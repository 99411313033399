import teamImg from "../../../assets/images/icons/team.png";
import { ButtonRound } from "../../ui/ButtonRound/ButtonRound";
import "./TeamRow.scss";

export const TeamRow = ({ onDeleteTeam, onExitTeam, item }) => {
  const { name, isAdmin, _id, usersCount } = item;

  return (
    <li className="team-row">
      <span className="team-row__name">{name}</span>
      {isAdmin && <span className="team-row__admin-icon" />}
      <span className="team-row__count">
        <img className="team-row__team-icon" src={teamImg} alt="" />
        {usersCount}
      </span>
      {isAdmin ? (
        <ButtonRound
          onClick={() => onDeleteTeam(_id)}
          icon="delete"
          type="grey"
          size="small"
        />
      ) : (
        <ButtonRound
          onClick={() => onExitTeam(_id)}
          icon="exit"
          type="grey"
          size="small"
        />
      )}
    </li>
  );
};
