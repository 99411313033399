import { memo } from "react";
import { Logo } from "../Logo/Logo";
import { Title } from "../Title/Title";

import "./PageHeader.scss";

export const PageHeader = memo(({ withLogo = false, children }) => {
  return (
    <div className="page-header">
      <div className="page-header__inner">
        <Title baseClass="page-header">{children}</Title>
        {withLogo && <Logo baseClass="page-header" />}
      </div>
    </div>
  );
});
