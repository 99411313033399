import { request } from "./utils";
import axios from "axios";
const { NODE_ENV, REACT_APP_URL_PROD, REACT_APP_URL_DEV } = process.env;

export const COMMON_GOALS_ENDPOINT = "/goals/is-common";

const axiosInstance = axios.create({
  baseURL: NODE_ENV === "production" ? REACT_APP_URL_PROD : REACT_APP_URL_DEV,
});

export const getCommonGoals = async () =>
  axiosInstance
    .get(COMMON_GOALS_ENDPOINT, { withCredentials: true })
    .then((res) => res.data);

/**
 * Получение сохраненных пользователем фильмов
 * @returns { Promise.<{Object[]}> } - возвращаемый объект содержит массив фильмов
 */
export const getGoals = () => {
  return request(`/goals`, {
    credentials: "include",
  });
};

/**
 * Создание сохраненного пользователем фильма
 * @param { Object } movie - объект с данными о фильме
 * @returns { Promise.<{Object[]}> } - возвращаемый объект содержит объект с данными о фильме
 */
export const createGoal = async (goalData) => {
  const formData = new FormData();
  for (let key in goalData) {
    formData.append(key, goalData[key]);
  }

  const { data } = await axios.post(
    `${
      NODE_ENV === "production" ? REACT_APP_URL_PROD : REACT_APP_URL_DEV
    }/goals`,
    formData,
    {
      withCredentials: true,
    }
  );

  return data;
};

/**
 * @param { Object } movie - объект с данными о фильме
 * @returns { Promise.<{Object[]}> } - возвращаемый объект содержит объект с данными о фильме
 */
export const completeGoal = (id) => {
  return request(`/goals/${id}`, {
    method: "POST",
    credentials: "include",
  });
};

/**
 * Удаление сохраненного пользовталем фильм
 * @param { string } id - id фильма
 */
export const deleteGoal = (id) => {
  return request(`/goals/${id}`, {
    method: "DELETE",
    credentials: "include",
  });
};

export const updateGoal = (goalData, id) => {
  return request(`/goals/${id}`, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...goalData,
    }),
  });
};
