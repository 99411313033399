import "./InfoPopup.scss";

export const InfoPopup = ({
  response,
  isInfoPopupOpen,
  onSetIsInfoPopupOpen,
}) => {
  const handleClick = () => {
    onSetIsInfoPopupOpen(false);
  };

  return (
    <div className={`info-popup ${isInfoPopupOpen ? "info-popup_opened" : ""}`}>
      <div className="info-popup__container">
        <p className="info-popup__text">{response}</p>
        <button
          className="info-popup__close-btn btn"
          aria-label="Закрыть окно"
          type="button"
          onClick={handleClick}
        />
      </div>
    </div>
  );
};
