import { Link } from "react-router-dom";
import "./ButtonRounded.scss";

export const ButtonRounded = ({
  onClick,
  icon,
  type,
  baseClass,
  children,
  isSubmit,
  href,
  isActive,
  dataName,
  ...props
}) => {
  const classList = `button-rounded ${baseClass ? baseClass : ""} ${
    type ? `button-rounded_type_${type}` : ""
  } ${icon ? `button-rounded_icon_${icon}` : ""} ${isActive ? "_active" : ""}`;

  return href ? (
    <Link to={href} className={classList}>
      {children}
    </Link>
  ) : (
    <button
      type={isSubmit ? "submit" : "button"}
      onClick={onClick}
      className={classList}
      data-name={dataName || null}
      {...props}
    >
      {children}
    </button>
  );
};
