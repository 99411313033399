import { Spoiler } from "../../common/Spoiler/Spoiler";
import { CreateTeamForm } from "../../forms/CreateTeamForm/CreateTeamForm";

export const CreateTeam = (props) => {
  return (
    <Spoiler btnAriaLabel="Открыть форму" name="Создать команду">
      <CreateTeamForm {...props} />
    </Spoiler>
  );
};
