import PropTypes from "prop-types";
import { Form } from "../../common/Form/Form";
import { ButtonRounded } from "../../ui/ButtonRounded/ButtonRounded";

import "./PopupWithForm.scss";

const PopupWithForm = ({
  isOpen = true,
  onClose,
  onSubmit,
  name,
  title,
  children,
  cardId,
  onDeleteCard,
  isLoading,
  isSubmitBlocking,
  isValid = true,
}) => {
  const handleDeleteCard = () => {
    onDeleteCard(cardId);
  };

  return (
    <div className={`popup ${isOpen && "popup_opened"} popup_type_${name}`}>
      <div className="popup__container">
        <h2 className="popup__title">{title}</h2>
        <button
          className="popup__close-btn btn"
          aria-label="Закрыть окно"
          type="button"
          onClick={onClose}
          disabled={isLoading}
        />
        <Form
          onSubmit={onSubmit}
          baseClass="popup__form"
          id={name}
          name={name}
          isLoading={isLoading}
        >
          {children}
          {onDeleteCard && (
            <ButtonRounded
              onClick={handleDeleteCard}
              type="gray"
              baseClass="popup__delete"
              disabled={isLoading}
            >
              Удалить
            </ButtonRounded>
          )}

          {!isSubmitBlocking && (
            <ButtonRounded
              isSubmit="true"
              type="blue"
              baseClass="popup__save"
              disabled={isLoading || !isValid}
            >
              Сохранить
            </ButtonRounded>
          )}
        </Form>
      </div>
    </div>
  );
};

PopupWithForm.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  name: PropTypes.string,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  children: PropTypes.node,
};

export default PopupWithForm;
