import { Card } from "../Card/Card";
import "./GoalsList.scss";

export const GoalsList = ({ showedGoals, ...props }) => {
  return (
    <>
      {showedGoals.length > 0 ? (
        <ul className="goals__list">
          {showedGoals.map((item, index) => (
            <Card component="li" key={index} item={item} {...props} />
          ))}
        </ul>
      ) : (
        <p className="goals__empty">Тут пока пусто</p>
      )}
    </>
  );
};
