import { PageHeader } from "../../components/common/PageHeader/PageHeader";
import { CreateCount } from "../../components/blocks/CreateCount/CreateCount";
import { CreateGoal } from "../../components/blocks/CreateGoal/CreateGoal";
import { JoinTeam } from "../../components/blocks/JoinTeam/JoinTeam";

import "./Goal.scss";
import { CreateTeam } from "../../components/blocks/CreateTeam/CreateTeam";
import Preloader from "../../components/common/Preloader/Preloader";

export const Goal = ({
  isFormBlocked,
  onCreateGoal,
  userTeams,
  onSetUserTeams,
  onSetUserGoals,
  onSetResponse,
  onSetIsInfoPopupOpen,
  onSetUserCounters,
  isLoading,
  isProfileLoading,
}) => {
  return (
    <section className="goal">
      <PageHeader withLogo={true}>Создать</PageHeader>
      <div className="goal__inner">
        {isProfileLoading && <Preloader />}
        <JoinTeam
          isFormBlocked={isFormBlocked}
          onSetUserTeams={onSetUserTeams}
          onSetUserGoals={onSetUserGoals}
          onSetResponse={onSetResponse}
          onSetIsInfoPopupOpen={onSetIsInfoPopupOpen}
          userTeams={userTeams}
        />
        <CreateTeam
          isFormBlocked={isFormBlocked}
          onSetUserTeams={onSetUserTeams}
          onSetResponse={onSetResponse}
          onSetIsInfoPopupOpen={onSetIsInfoPopupOpen}
        />
        <CreateCount
          isFormBlocked={isFormBlocked}
          onSetResponse={onSetResponse}
          onSetIsInfoPopupOpen={onSetIsInfoPopupOpen}
          onSetUserCounters={onSetUserCounters}
        />
        <CreateGoal
          isFormBlocked={isFormBlocked}
          onCreateGoal={onCreateGoal}
          userTeams={userTeams}
          isLoading={isLoading}
        />
      </div>
    </section>
  );
};
