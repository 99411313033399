import { Spoiler } from "../../common/Spoiler/Spoiler";
import { CreateCounterForm } from "../../forms/CreateCounterForm/CreateCounterForm";

export const CreateCount = (props) => {
  return (
    <Spoiler btnAriaLabel="Открыть форму" name="Создать счетчик">
      <CreateCounterForm {...props} />
    </Spoiler>
  );
};
