import meImg from "../../../assets/images/icons/green-ok.svg";
import star from "../../../assets/images/icons/gold-star.svg";

import "./TeamUserRatingRow.scss";

export const TeamUserRatingRow = ({ user, isFirst, isMine }) => {
  const { avatar, name, count } = user;

  return (
    <li className={`team-user-rating-row ${isMine ? "_mine" : ""}`}>
      <img
        className="team-user-rating-row__avatar"
        src={avatar}
        aria-hidden="true"
      />
      <span className="team-user-rating-row__name">{name}</span>
      {isFirst && (
        <img
          className="team-user-rating-row__avatar"
          src={star}
          aria-hidden="true"
        />
      )}
      <span className="team-user-rating-row__count">{count}</span>
    </li>
  );
};
