import { ButtonRound } from "../../ui/ButtonRound/ButtonRound";
import teamIcon from "../../../assets/images/icons/team.png";
import { useContext, useEffect, useState } from "react";
import { ProgressBar } from "../ProgressBar/ProgressBar";
import { GROUPS_EN } from "../../../utils/constants";
import { DayTag } from "../../ui/DayTag/DayTag";
import "./RecommCard.scss";
import { CurrentUserContext } from "../../../contexts/CurrentUserContext";
import { ButtonRounded } from "../../ui/ButtonRounded/ButtonRounded";

export const RecommCard = ({ item }) => {
  const {
    name,
    date,
    team,
    maxCount,
    unit,
    image,
    group,
    teamUsers,
    _id,
    interval,
    maxPerOne,
  } = item;
  const [isCompleted, setIsCompleted] = useState(false);
  const [isTimeTiFill, setIsTimeTiFill] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [goalStatus, setGoalStatus] = useState({
    completed: false,
    count: 0,
    name: "",
    progress: 0,
    lastUpdated: "",
    isAdmin: false,
  });

  const handleAddClick = () => {
    console.log("add");
  };

  const handleEditClick = () => {
    console.log("edit");
  };

  const isLetString = (string) => {
    const letReg = /^[a-zA-Z]+$/;
    return letReg.test(string);
  };

  const asciiSum = (string) => {
    return string
      .toLowerCase()
      .split("")
      .reduce((acc, letter) => acc + letter.charCodeAt(), 0);
  };

  const compare = (s1, s2) => {
    if (!isLetString(s1) && !isLetString(s2)) return true;
    if (!isLetString(s1) || !isLetString(s2)) return false;

    return asciiSum(s1);
  };

  const user = useContext(CurrentUserContext);

  return (
    <li className={`card `}>
      <div className={`card__title-container `}>
        <h3 className="card__title">{name}</h3>
        <div
          className={`card__image-container ${
            GROUPS_EN.includes(group)
              ? `card__image-container_type_${group}`
              : ""
          }`}
        ></div>
      </div>

      <div className="card__inner-container">
        <div className="card__tags">
          {team && team !== "me" && (
            <div className="card__team">
              <img src={teamIcon} alt="" />
              <span>{team}</span>
            </div>
          )}
          {!goalStatus.completed && <DayTag date={date} />}
        </div>
        {maxCount > 1 && (
          <div className="card__progress">
            <span className="card__progress-text">Прогресс</span>
            <span className="card__progress-text">{`${goalStatus.count} / ${maxCount} ${unit}`}</span>
            <ProgressBar
              isCompleted={goalStatus.completed}
              progress={(goalStatus.count * 100) / maxCount}
            />
          </div>
        )}
        <div className="card__handlers">
          {user && (
            <ButtonRounded
              type="gray"
              onClick={handleEditClick}
              baseClass="card__edit"
            >
              Редактировать
            </ButtonRounded>
          )}
          <ButtonRounded type="green" onClick={handleAddClick}>
            Участвовать
          </ButtonRounded>
        </div>
        {image && (
          <div className="card__bg-container">
            <img src={image} alt={`Картинка для цели ${name}`} />
          </div>
        )}
      </div>
    </li>
  );
};
