import "./RadioTag.scss";

export const RadioTag = ({ children, disabled, ...props }) => {
  return (
    <label className="radio-tag" disabled={disabled}>
      {children}
      <input className="radio-tag__input" type="radio" {...props} />
    </label>
  );
};
