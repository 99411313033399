import { useContext, useEffect, useState } from "react";
import { DayTag } from "../../ui/DayTag/DayTag";
import { CurrentUserContext } from "../../../contexts/CurrentUserContext";
import { getResidueTime } from "../../../helpers/getResidueTime";
import { CardHandlers } from "./CardHandlers/CardHandlers";
import { CardBgImage } from "./CardBgImage/CardBgImage";
import { CardProgress } from "./CardProgress/CardProgress";
import { CardLabel } from "./CardLabel/CardLabel";
import teamIcon from "../../../assets/images/icons/team.png";
import { Tag } from "../../ui/Tag/Tag";

import "./Card.scss";

export const Card = ({
  component: Component,
  item,
  isFillPopupOpen,
  ...props
}) => {
  const {
    name,
    date,
    team,
    maxCount,
    unit,
    image,
    group,
    teamUsers,
    interval,
    isActive,
  } = item;
  const [isTimeTiFill, setIsTimeTiFill] = useState(true);
  const [goalStatus, setGoalStatus] = useState({
    completed: false,
    count: 0,
    name: "",
    progress: 0,
    lastUpdated: "",
    isAdmin: false,
  });

  const user = useContext(CurrentUserContext);

  useEffect(() => {
    if (teamUsers) {
      const myGoal = teamUsers.find((teamUser) => teamUser._id === user._id);

      const { name, progress, count, completed, isAdmin, lastUpdated } = myGoal;

      setGoalStatus({
        isAdmin,
        name,
        progress,
        count,
        completed,
        lastUpdated,
      });
    }
  }, [teamUsers]);

  useEffect(() => {
    const timeToFill =
      !goalStatus.lastUpdated ||
      getResidueTime(goalStatus.lastUpdated, +interval) < 0;

    setIsTimeTiFill(timeToFill);
  }, [goalStatus.lastUpdated, isFillPopupOpen]);

  return (
    <Component className={`card `}>
      <div className="card__title-container">
        <h3 className="card__title">{name}</h3>
        <CardLabel group={group} />
      </div>
      <div className="card__inner-container">
        <div className="card__tags">
          {team && team !== "me" && <Tag icon={teamIcon} name={team} />}
          {!goalStatus.completed && isActive && <DayTag date={date} />}
        </div>
        {maxCount > 1 && (
          <CardProgress
            completed={goalStatus.completed}
            count={goalStatus.count}
            maxCount={maxCount}
            unit={unit}
          />
        )}
        <CardHandlers
          isTimeTiFill={isTimeTiFill}
          goalStatus={goalStatus}
          item={item}
          {...props}
        />
        {image && <CardBgImage image={image} name={name} />}
      </div>
    </Component>
  );
};
