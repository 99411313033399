import { useState, useEffect } from "react";
import { useFormWithValidation } from "../../../hooks/useFormWithValidation";
import { Form } from "../../common/Form/Form";
import { ButtonRound } from "../../ui/ButtonRound/ButtonRound";
import { ButtonRounded } from "../../ui/ButtonRounded/ButtonRounded";
import * as goalsApi from "../../../utils/goalApi";
import { InputBlock } from "../../blocks/InputBlock/InputBlock";

import "./FillGoalForm.scss";

export const FillGoalForm = ({ isOpen, onClose, currentGoal, onSetGoals }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currenCount, setCurrentCount] = useState(0);
  const [disabledIncr, setDisabledIncr] = useState(false);
  const [disabledDecr, setDisabledDecr] = useState(false);
  const [fillState, setFillState] = useState(true);
  const [maxChangeValue, setMaxChangeValue] = useState(0);
  const [residueTime, setResidueTime] = useState({
    days: "",
    hours: "",
    minutes: "",
  });

  useEffect(() => {
    if (isOpen) {
      if (!currentGoal.lastUpdated) {
        return;
      } else {
        const residueTimeToFill =
          new Date(currentGoal.lastUpdated).getTime() +
          +currentGoal.interval -
          new Date().getTime();
        const minutes = +(residueTimeToFill / 1000 / 60).toFixed(0);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        setResidueTime({
          days: days,
          hours: hours % 24,
          minutes: minutes % 60,
        });
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      const { maxPerOne, maxCount, count } = currentGoal;

      const changeValue =
        maxPerOne && count + maxPerOne < maxCount
          ? maxPerOne - (currenCount - count)
          : fillState
          ? maxCount - currenCount
          : currenCount;

      setMaxChangeValue(changeValue);
    }
  }, [isOpen, currenCount, fillState]);

  useEffect(() => {
    if (isOpen) {
      setValues({ count: 0 });
      setCurrentCount(currentGoal.count);
      setDisabledDecr(currentGoal.count === 0);
      setDisabledIncr(currentGoal.count === currentGoal.maxCount);
    }
  }, [isOpen]);

  const { values, handleChange, handleSubmit, setValues } =
    useFormWithValidation({
      count: 0,
    });

  const submitForm = handleSubmit(async () => {
    const res = fillState
      ? currenCount + +values.count
      : currenCount - values.count;

    if (currentGoal.count === res) {
      onClose();
    } else {
      setIsLoading(true);

      try {
        const newGoal = await goalsApi.updateGoal(
          { count: res },
          currentGoal.id
        );
        onSetGoals((goals) =>
          goals.map((g) => (g._id === newGoal._id ? newGoal : g))
        );
        setIsLoading(false);
        onClose();
      } catch (err) {
        setIsLoading(false);
        console.log(err);
      }
    }
  });

  const handleIncreaseClick = () => {
    setCurrentCount(currenCount + 1);
    let limitPerOne = null;

    if (currentGoal.maxPerOne) {
      limitPerOne =
        currenCount + 1 - currentGoal.count >= currentGoal.maxPerOne;
    }

    setDisabledIncr(limitPerOne || currenCount + 1 === currentGoal.maxCount);
    setDisabledDecr(false);
  };

  const handleDescreaseClick = () => {
    setCurrentCount(currenCount - 1);
    setDisabledDecr(currenCount - 1 === 0);
    setDisabledIncr(false);
  };

  return (
    <Form
      onSubmit={submitForm}
      id="fill-goal"
      name="fill"
      isLoading={isLoading}
      baseClass="fill-goal-form"
    >
      {currentGoal.isTimeTiFill ? (
        <>
          {currentGoal.maxPerOne && (
            <div className="fill-goal-form__max-value">
              Возможное увеличение - {currentGoal.maxPerOne}
            </div>
          )}
          <InputBlock
            labelText="Количество"
            baseClass="goal"
            labelPos="top"
            id="goal-fill"
            type="number"
            name="count"
            isBordered={true}
            value={values.count}
            onChange={handleChange}
            min={0}
            max={maxChangeValue}
            disabled={isLoading}
          />
          <div className="fill-goal-form__tabs">
            <ButtonRounded
              type="light-bordered"
              isActive={fillState}
              onClick={() => setFillState(true)}
              disabled={isLoading}
            >
              Добавить
            </ButtonRounded>
            <ButtonRounded
              type="light-bordered"
              isActive={!fillState}
              onClick={() => setFillState(false)}
              disabled={isLoading}
            >
              Убрать
            </ButtonRounded>
          </div>
          {currentGoal.maxCount > 1 && (
            <div className="fill-goal-form__count-container">
              <p className="goal__count-title">Выполнено</p>
              <div className="goal__counter">
                <span
                  className="goal__input-count"
                  style={{ width: (String(currenCount).length + 1) * 8 }}
                >
                  {currenCount}
                </span>
                <span className="goal__max-count">{`/ ${currentGoal.maxCount} ${
                  currentGoal.unit ?? ""
                }`}</span>
              </div>
              <div className="goal__count-handlers">
                <ButtonRound
                  onClick={handleDescreaseClick}
                  icon="minus"
                  type="green"
                  baseClass={`card__complete ${
                    disabledDecr ? "disabledBtn" : ""
                  }`}
                  size="small"
                  disabled={isLoading}
                />
                <ButtonRound
                  onClick={handleIncreaseClick}
                  icon="plus"
                  type="green"
                  baseClass={`card__complete ${
                    disabledIncr ? "disabledBtn" : ""
                  }`}
                  size="small"
                  disabled={isLoading}
                />
              </div>
            </div>
          )}
          <ButtonRounded
            isSubmit="true"
            type="blue"
            baseClass="fill-goal-form__save"
            disabled={isLoading}
          >
            Сохранить
          </ButtonRounded>
        </>
      ) : (
        <div className="fill-goal-form__residue-container">
          <span className="fill-goal-form__residue-title">
            До следущего пополнения осталось
          </span>
          <span className="fill-goal-form__residue-count">
            {residueTime.days >= 1 && (
              <div className="fill-goal-form__residue-number-container">
                <span className="fill-goal-form__residue-number">{`${residueTime.days}`}</span>
                <span className="fill-goal-form__residue-caption">д</span>
              </div>
            )}
            {residueTime.hours >= 1 && (
              <div className="fill-goal-forml__residue-number-container">
                <span className="fill-goal-form__residue-number">{`${residueTime.hours}`}</span>
                <span className="fill-goal-form__residue-caption">ч</span>
              </div>
            )}
            {residueTime.minutes >= 1 && (
              <div className="fill-goal-form__residue-number-container">
                <span className="fill-goal-form__residue-number">{`${residueTime.minutes}`}</span>
                <span className="fill-goal-form__residue-caption">мин</span>
              </div>
            )}

            {residueTime.days < 1 &&
              residueTime.hours < 1 &&
              residueTime.minutes < 1 && (
                <span className="fill-goal-form__residue-caption">
                  менее 1 мин
                </span>
              )}
          </span>
        </div>
      )}
    </Form>
  );
};
