export const filterGoals = (
  goals,
  user,
  selectedTeam = "",
  isCompleted = true
) => {
  return goals.filter((goal) => {
    const myGoal = goal.teamUsers.find((teamUser) => teamUser._id === user._id);

    if (selectedTeam) {
      if (goal.isActive) {
        return myGoal.completed === isCompleted && goal.team === selectedTeam;
      } else return isCompleted && goal.team === selectedTeam;
    } else {
      if (goal.isActive) return myGoal.completed === isCompleted;
      else return isCompleted;
    }
  });
};
