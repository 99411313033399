import "./Preloader.scss";

const Preloader = () => {
  return (
    <div className="preload__container">
      <div className="preload">
        <span className="preload__green preload__green_pos_left" />
        <span className="preload__green preload__green_pos_right" />
        <span className="preload__white preload__white_pos_left" />
        <span className="preload__white preload__white_pos_right" />
      </div>
    </div>
  );
};

export default Preloader;
