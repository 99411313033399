const { NODE_ENV, REACT_APP_URL_PROD, REACT_APP_URL_DEV } = process.env;

/**
 * Проверка ответа на запрос к серверу
 * @param { Promise } res - возвращаемый при fetch-запросе объект
 * @returns { Object } - возвращаемый объект переведен в json-формат и содержит готовые данные
 */
const getResponseData = (res) => {
  if (!res.ok) {
    return Promise.reject(res.status);
  }
  return res.json();
};

/**
 * Осуществление запроса к серверу
 * @param { string } url - эндпойнт запроса
 * @param { string } options - объект конфигурации запроса
 * @returns { Promise } - возвращаемый объект переведен в json-формат и содержит готовые данные
 */

const request = (url = "/", options) => {
  return fetch(
    `${
      NODE_ENV === "production" ? REACT_APP_URL_PROD : REACT_APP_URL_DEV
    }${url}`,
    options
  ).then((res) => getResponseData(res));
};

/**
 * Получение сохраненных пользователем фильмов
 * @returns { Promise.<{Object[]}> } - возвращаемый объект содержит массив фильмов
 */
export const getTeams = () => {
  return request("/teams/me", {
    credentials: "include",
  });
};

export const getExampleTeams = (name) => {
  return request(`/teams/${name}`, {
    credentials: "include",
  });
};

/**
 * Создание команды
 * @param { Object } team - объект с данными о команде
 * @returns { Promise.<{Object[]}> } - возвращаемый объект содержит объект с данными о фильме
 */
export const createTeam = (team) => {
  return request("/teams", {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...team,
    }),
  });
};

/**
 * Создание команды
 * @param { Object } team - объект с данными о команде
 * @returns { Promise.<{Object[]}> } - возвращаемый объект содержит объект с данными о фильме
 */
export const joinToTeam = (teamName) => {
  return request("/teams/users", {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...teamName,
    }),
  });
};

/**
 * Создание команды
 * @param { Object } team - объект с данными о команде
 * @returns { Promise.<{Object[]}> } - возвращаемый объект содержит объект с данными о фильме
 */
export const leaveTeam = (id) => {
  return request(`/teams/${id}/users`, {
    method: "DELETE",
    credentials: "include",
  });
};

/**
 * Создание команды
 * @param { Object } team - объект с данными о команде
 * @returns { Promise.<{Object[]}> } - возвращаемый объект содержит объект с данными о фильме
 */
export const deleteTeam = (id) => {
  return request(`/teams/${id}`, {
    method: "DELETE",
    credentials: "include",
  });
};
