import { Subtitle } from "../../blocks/Subtitle/Subtitle";
import { CreateGoalForm } from "../../forms/CreateGoalForm/CreateGoalForm";

import "./CreateGoal.scss";

export const CreateGoal = (props) => {
  return (
    <section className="goal__create-goal">
      <Subtitle baseClass="goal">Поставить цель</Subtitle>
      <CreateGoalForm {...props} />
    </section>
  );
};
