import { useContext, useEffect, useState } from "react";
import { Logo } from "../../components/common/Logo/Logo";
import { Title } from "../../components/common/Title/Title";
import { CurrentUserContext } from "../../contexts/CurrentUserContext";
import * as auth from "../../utils/mainApi";
import {
  Chart as ChartJS,
  BarElement,
  Title as BarTtile,
  CategoryScale,
  LinearScale,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import "./Rating.scss";
import sport from "../../assets/images/icons/ball.png";

ChartJS.register(BarElement, BarTtile, LinearScale, CategoryScale);

export const Rating = ({ rating, onGetCompleteGoals, isLogged }) => {
  const [sc, setSc] = useState({});
  const [ratingData, setRatingData] = useState([]);
  const [ratingValues, setRatingValues] = useState([]);
  const [ratingUsers, setRatingUsers] = useState([]);

  const labels = ["Спорт", "Природа", "Красота", "Учеба", "Другое"];

  const options = {
    // indexAxis: "y",
    responsive: true,
    plugins: {},
    scales: {
      x: {
        grid: {
          drawOnChartArea: false,
        },
      },
    },
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
  };

  const asd = [10, 4, 6, 7, 3, 1];

  const data = {
    labels,
    datasets: [
      {
        // axis: "y",
        label: "Dataset 1",
        data: asd,
        backgroundColor: "#80d0c7",
        borderColor: "#424242",
        borderRadius: "5",
      },
    ],
  };

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const user = await auth.getUserInfo();
        setSc(user);
        setRatingData(Object.values(user.score));
        setRatingValues(Object.keys(user.score));
      } catch (err) {
        console.log(err);
      }
    };

    if (isLogged) {
      fetchUserInfo();
    }
  }, []);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const users = await auth.getUsers();
        setRatingUsers(users);
      } catch (err) {
        console.log(err);
      }
    };

    if (isLogged) {
      fetchUserInfo();
    }
  }, []);

  const ratingUsersFake = [
    { name: "John", score: "10" },
    { name: "Smith", score: "9" },
    { name: "Will", score: "8" },
    { name: "Charles", score: "7" },
    { name: "Clara", score: "6" },
  ];

  return (
    <section className="rating">
      <div className="rating__header">
        <Title baseClass="rating">Рейтинг</Title>
        <Logo baseClass="rating" />
      </div>
      <div className="rating__container">
        <div className="rating__banner">
          <p className="rating__text">
            Выполни 15 целей в любой тематике и получи свой аватар
          </p>
        </div>
        <div className="rating__charts">
          <Title baseClass="rating">Моя статистика</Title>
          <Bar className="rating__chart" options={options} data={data} />
        </div>
        <div className="rating__rating">
          <div className="rating__inner">
            <div className="rating__head">
              <Title baseClass="rating">СПОРТ</Title>
              {/* <img src={sport} alt="" /> */}
            </div>
            <ul className="rating__list">
              {ratingUsers &&
                ratingUsers.map((user, index) => (
                  <li className="rating__item" key={index}>
                    <span className="rating__name">{user.name}</span>
                    <span className="rating__line"></span>
                    <span className="rating__count">{user.score}</span>
                  </li>
                ))}
            </ul>
          </div>
          <div className="rating__inner">
            <Title baseClass="rating">ПРИРОДА</Title>
            <ul className="rating__list">
              {ratingUsers &&
                ratingUsers.map((user, index) => (
                  <li className="rating__item" key={index}>
                    <span className="rating__name">{user.name}</span>
                    <span className="rating__line"></span>
                    <span className="rating__count">{user.score}</span>
                  </li>
                ))}
            </ul>
          </div>
          <div className="rating__inner">
            <Title baseClass="rating">КРАСОТА</Title>
            <ul className="rating__list">
              {ratingUsersFake &&
                ratingUsersFake.map((user, index) => (
                  <li className="rating__item" key={index}>
                    <span className="rating__name">{user.name}</span>
                    <span className="rating__line"></span>
                    <span className="rating__count">{user.score}</span>
                  </li>
                ))}
            </ul>
          </div>
          <div className="rating__inner">
            <Title baseClass="rating">УЧЕБА</Title>
            <ul className="rating__list">
              {ratingUsersFake &&
                ratingUsersFake.map((user, index) => (
                  <li className="rating__item" key={index}>
                    <span className="rating__name">{user.name}</span>
                    <span className="rating__line"></span>
                    <span className="rating__count">{user.score}</span>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
