import Popup from "../../common/Popup/Popup";
import { EditGoalForm } from "../../forms/EditGoalForm/EditGoalForm";

export const EditGoalPopup = ({ isOpen, onClose, ...props }) => {
  return (
    <Popup isOpen={isOpen} onClose={onClose} title="Редактировать цель">
      <EditGoalForm onClose={onClose} {...props} />
    </Popup>
  );
};
