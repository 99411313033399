export const MESSAGES = {
  successCreateGoal: "Цель успешно создана!",
  successCreateCounter: "Счетчик успешно создан!",
  successCreateTeam: "Команда успешно создана!",
  successJoinTeam: "Вы присоединились к команде",
  teamExistError: "Команда с таким названием уже существует",

  serverError: "500 На сервере произошла ошибка.",
  notFoundError: "404 Страница по указанному маршруту не найдена.",
  emailExistError: "Пользователь с таким email уже существует.",
  profileError: "При обновлении профиля произошла ошибка.",
  registerError: "При регистрации пользователя произошла ошибка.",
  incorrectSignin: "Вы ввели неправильный логин или пароль.",
  tokenExistError:
    "При авторизации произошла ошибка. Токен не передан или передан не в том формате.",
  incorrectToken:
    "При авторизации произошла ошибка. Переданный токен некорректен.",
  incorrectName:
    "Имя может содержать только латиницу, кириллицу, пробел и дефис",
  incorrectEmail: "Введите корректный email-адрес",
  contentNotFound: "Ничего не найдено",
  searchNameError: "Введите ключевое слово",
  successEditProfileMessage: "Данные успешно обновлены",
  contentSearchError:
    "Во время запроса произошла ошибка. Возможно, проблема с соединением или сервер недоступен. Подождите немного и попробуйте ещё раз",
};

export const GROUPS_EN = ["sport", "nature", "study", "beauty", "another"];

export const GROUPS = {
  sport: "Спорт",
  nature: "Природа",
  beauty: "Красота",
  study: "Учеба",
  another: "Другое",
};

export const MAX_EXAMPLE_COUNT = 8;
