import { useContext, useEffect } from "react";
import { useState } from "react";
import { CurrentUserContext } from "../../../contexts/CurrentUserContext";
import userImg from "../../../assets/images/icons/user.png";
import { ButtonRounded } from "../../ui/ButtonRounded/ButtonRounded";
import { filterGoals } from "../../../helpers/filterGoals";

import "./GoalsHandlers.scss";

export const GoalsHandlers = ({ goals, setShowedGoals }) => {
  const [completedFilterState, setCompletedFilterState] = useState(false);
  const [filterTeam, setFilterTeam] = useState([]);
  const [selectedFilterTeam, setSelectedFilterTeam] = useState("");
  const user = useContext(CurrentUserContext);

  useEffect(() => {
    const completedGoals = filterGoals(goals, user, "", completedFilterState);

    setShowedGoals(completedGoals);
    setTeamFilterTags(completedGoals);
  }, [goals]);

  const setTeamFilterTags = (array) => {
    const arr = Array.from(new Set(array.map((goal) => goal.team))).sort();
    setSelectedFilterTeam("");
    setFilterTeam(arr);
  };

  const handleTeamClick = ({ target }) => {
    const targetValue = target.dataset.name;
    const teamFilterValue =
      targetValue === selectedFilterTeam ? "" : targetValue;
    const filteredTeamGoals = filterGoals(
      goals,
      user,
      teamFilterValue,
      completedFilterState
    );

    setSelectedFilterTeam(teamFilterValue);
    setShowedGoals(filteredTeamGoals);
  };

  const setFilteredGoals = (isCompleted = true) => {
    const goalsList = filterGoals(goals, user, "", isCompleted);

    setShowedGoals(goalsList);
    setCompletedFilterState(isCompleted);
    setTeamFilterTags(goalsList);
    setSelectedFilterTeam("");
  };

  return (
    <div className="goals-handlers">
      <div className="goals-handlers__tabs">
        <ButtonRounded
          type="light-bordered"
          isActive={!completedFilterState}
          onClick={() => setFilteredGoals(false)}
        >
          Активные
        </ButtonRounded>
        <ButtonRounded
          type="light-bordered"
          isActive={completedFilterState}
          onClick={() => setFilteredGoals()}
        >
          Завершенные
        </ButtonRounded>
      </div>
      {filterTeam.length > 1 && (
        <div
          className="goals-handlers__team-filter"
          style={{
            justifyContent: `${
              filterTeam.length <= 3 ? "center" : "flex-start"
            }`,
          }}
        >
          {filterTeam.map((team, index) => (
            <ButtonRounded
              key={index}
              type="light-bordered"
              isActive={selectedFilterTeam === team}
              onClick={handleTeamClick}
              baseClass="goals-handlers__team-btn"
              dataName={team}
            >
              {team === "me" && (
                <img
                  src={userImg}
                  alt=""
                  className="goals-handlers__checkbox-label-icon"
                />
              )}
              {team === "me" ? "Мои" : team}
            </ButtonRounded>
          ))}
        </div>
      )}
    </div>
  );
};
