import PropTypes from "prop-types";
import "./Subtitle.scss";

/**
 * Компонент заголовка
 * @component
 * @param { Object } props
 * @param { string } props.theme - тема, которая определяет стилизацию элемента (например, dark)
 * @param { string | string[] } props.children - текст заголовка
 */
export const Subtitle = ({ children, baseClass }) => {
  return <h2 className={`subtitle ${baseClass}__subtitle`}>{children}</h2>;
};

Subtitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};
