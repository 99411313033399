import useSWR from "swr";
import { Logo } from "../../components/common/Logo/Logo";
import Preloader from "../../components/common/Preloader/Preloader";
import { RecommCard } from "../../components/blocks/RecommCard/RecommCard";
import { Title } from "../../components/common/Title/Title";
import { COMMON_GOALS_ENDPOINT, getCommonGoals } from "../../utils/goalApi";
import "./Recommendations.scss";

export const Recommendations = () => {
  const { data, error, isLoading } = useSWR(
    COMMON_GOALS_ENDPOINT,
    getCommonGoals
  );

  if (isLoading) return <Preloader />;

  return (
    <section className="goals">
      <div className="goals__header">
        <div className="goals__header-inner">
          <Title baseClass="goals">Рекомендации</Title>
          <Logo baseClass="goals" />
        </div>
      </div>
      <ul className="goals__list">
        {data.length > 0 ? (
          data.map((item, index) => <RecommCard key={index} item={item} />)
        ) : (
          <p className="goals__empty">У вас пока нет целей</p>
        )}
      </ul>
    </section>
  );
};
