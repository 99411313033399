import { ButtonRound } from "../../../ui/ButtonRound/ButtonRound";
import { ButtonRounded } from "../../../ui/ButtonRounded/ButtonRounded";
import "./CardHandlers.scss";

export const CardHandlers = ({
  item,
  onEditGoal,
  onSetIsShowTeamPopupOpen,
  onSetShowPopupTeam,
  onSetCurrentGoalId,
  onSetConfirmCompletePopup,
  onSetIsReturnConfirmPopupOpen,
  onSetIsFinishPopupOpen,
  onSetIsActivePopupOpen,
  goalStatus,
  isTimeTiFill,
  setIsEditPopupOpen,
  setIsFillPopupOpen,
}) => {
  const {
    name,
    date,
    team,
    maxCount,
    unit,
    teamUsers,
    _id,
    interval,
    maxPerOne,
    isActive,
    teamId,
  } = item;

  const handleShowTeamClick = () => {
    onSetShowPopupTeam(teamUsers);
    onSetIsShowTeamPopupOpen(true);
  };

  const handleCompleteClick = () => {
    onSetCurrentGoalId(_id);
    onSetConfirmCompletePopup(true);
  };

  const handleReturnClick = () => {
    onSetCurrentGoalId(_id);
    onSetIsReturnConfirmPopupOpen(true);
  };

  const handleActiveGoalClick = () => {
    onSetCurrentGoalId(_id);
    onSetIsActivePopupOpen(true);
  };

  const handleFinishGoalClick = () => {
    onSetCurrentGoalId(_id);
    onSetIsFinishPopupOpen(true);
  };

  const handleFillGoalClick = () => {
    setIsFillPopupOpen(true);

    onEditGoal({
      name,
      date,
      count: goalStatus.count,
      maxCount,
      id: _id,
      isTeamExist: team,
      unit,
      lastUpdated: goalStatus.lastUpdated,
      interval,
      maxPerOne,
      isTimeTiFill: isTimeTiFill,
    });
  };

  const handleEditClick = () => {
    setIsEditPopupOpen(true);
    onEditGoal({
      name,
      date,
      count: goalStatus.count,
      maxCount,
      id: _id,
      isTeamExist: team,
      unit,
    });
  };

  return (
    <div className="card-handlers">
      {goalStatus.isAdmin && (
        <ButtonRounded
          type="gray"
          onClick={handleEditClick}
          baseClass="card__edit"
        >
          Редактировать
        </ButtonRounded>
      )}
      {teamUsers && teamUsers.length > 1 && (
        <ButtonRound
          ariaLabel="Посмотреть результаты команды по данной цели"
          onClick={handleShowTeamClick}
          icon="team"
          type="green"
          baseClass="card__user"
        />
      )}

      {maxCount > 1 && isActive && !goalStatus.completed && (
        <ButtonRound
          ariaLabel="Пополнить прогресс цели"
          onClick={handleFillGoalClick}
          icon="plus"
          type="green"
          baseClass="card__fill"
        />
      )}

      {teamId && goalStatus.isAdmin && isActive && (
        <ButtonRound
          ariaLabel="Завершить цель для всех"
          onClick={handleFinishGoalClick}
          icon="finish"
          type="green"
          baseClass="card__finish"
        />
      )}

      {teamId && goalStatus.isAdmin && !isActive && (
        <ButtonRound
          ariaLabel="Вернуть цель в Активные для всех"
          onClick={handleActiveGoalClick}
          icon="play"
          type="green"
          baseClass="card__play"
        />
      )}

      {goalStatus.completed && (
        <ButtonRound
          ariaLabel="Вернуть цель в незавершенные"
          onClick={handleReturnClick}
          icon="return"
          type="green"
          baseClass="card__complete"
        />
      )}

      {!goalStatus.completed &&
        isActive &&
        isTimeTiFill &&
        (maxPerOne ? goalStatus.count + maxPerOne >= maxCount : true) && (
          <ButtonRound
            ariaLabel="Завершить цель"
            onClick={handleCompleteClick}
            icon="complete"
            type="green"
            baseClass="card__complete"
          />
        )}
    </div>
  );
};
