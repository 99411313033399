import InputPreloader from "../../common/CirclePreloader/CirclePreloader";
import { Input } from "../../common/Input/Input";

import "./InputBlock.scss";

export const InputBlock = ({
  id,
  labelText,
  labelPos = "top",
  baseClass,
  errorText,
  isCustomValidated,
  type,
  ...props
}) => {
  return (
    <div
      className={`input__container ${
        type === "date" ? "input__container_type_date" : ""
      }`}
    >
      <label
        className={`input__label input__label_pos_${labelPos}`}
        htmlFor={id}
      >
        {labelText}
      </label>
      <Input
        errorText={errorText}
        isCustomValidated={isCustomValidated}
        id={id}
        type={type}
        {...props}
      />
      {isCustomValidated && (
        <span className={`input__error ${id}-error`}>{errorText}</span>
      )}
    </div>
  );
};
