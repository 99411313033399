import { useContext, useRef, useState } from "react";
import teamImg from "../../../assets/images/icons/team.png";
import { CurrentUserContext } from "../../../contexts/CurrentUserContext";
import { ButtonRound } from "../../ui/ButtonRound/ButtonRound";
import { TeamUserRatingRow } from "../TeamUserRatingRow/TeamUserRatingRow";

import "./TeamCard.scss";

export const TeamCard = ({ team, onDeleteTeam, onExitTeam }) => {
  const user = useContext(CurrentUserContext);
  const [isAdminCaptionOpened, setIsAdminCaptionOpened] = useState(false);

  const { name, isAdmin, _id, usersCount, users } = team;

  const [isSpoilerOpened, setIsSpoilerOpened] = useState(false);
  const [spoilerHeight, setSpoilerHeight] = useState(0);

  const ref = useRef(null);

  const handleOpenClick = () => {
    setIsSpoilerOpened(!isSpoilerOpened);
    setSpoilerHeight(isSpoilerOpened ? 0 : ref.current.scrollHeight);
  };

  const closeSpoiler = () => {
    setSpoilerHeight(0);
    setIsSpoilerOpened(false);
  };

  return (
    <div className="spoiler">
      <p className="spoiler__subtitle">{team.name}</p>
      <div className="team-card__handlers">
        {isAdmin && (
          <div className="team-card__admin">
            <span
              className={`team-card__admin-caption ${
                isAdminCaptionOpened ? "_opened" : ""
              }`}
            >
              Тут ничего интересного. Вы просто админ.
            </span>
            <ButtonRound
              onClick={() => setIsAdminCaptionOpened(!isAdminCaptionOpened)}
              icon="admin"
              type="green"
              size="small"
            />
          </div>
        )}
        <ButtonRound
          externalActive={isSpoilerOpened}
          ariaLabel=""
          onClick={handleOpenClick}
          type="grey"
          size="stretch"
          withAnim={true}
          baseClass="spoiler__btn"
        >
          <img className="team-card__team-icon" src={teamImg} alt="" />
          <span className="team-card__count">{usersCount}</span>
        </ButtonRound>
        {/* <span className="team-row__count">
        <img className="team-row__team-icon" src={teamImg} alt="" />
        {usersCount}
      </span> */}
        {isAdmin ? (
          <ButtonRound
            onClick={() => onDeleteTeam(_id)}
            icon="delete"
            type="grey"
            size="small"
          />
        ) : (
          <ButtonRound
            onClick={() => onExitTeam(_id)}
            icon="exit"
            type="grey"
            size="small"
          />
        )}
      </div>
      <div className="spoiler__outer" style={{ height: `${spoilerHeight}px` }}>
        <div ref={ref} className="spoiler__inner">
          <ul className="team-card__rating-list">
            {users.map((teamUser, idx) => (
              <TeamUserRatingRow
                key={idx}
                isFirst={
                  idx === 0 &&
                  users[idx + 1] &&
                  users[idx].count > users[idx + 1].count
                }
                isMine={teamUser._id === user._id}
                user={teamUser}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
