import { ButtonRound } from "../../ui/ButtonRound/ButtonRound";
import "./CounterRow.scss";

export const CounterRow = ({ onDeleteCounter, item }) => {
  const { name, unit, count } = item;

  return (
    <li className="counter-row">
      <span className="counter-row__name">{name}</span>
      <ButtonRound
        onClick={() => onDeleteCounter(item)}
        icon="plus"
        type="green"
        size="small"
      />
      <span className="counter-row__count">
        {count} {unit}
      </span>
    </li>
  );
};
