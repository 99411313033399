import { Link } from "react-router-dom";
import { Title } from "../../components/common/Title/Title";
import { Form } from "../../components/common/Form/Form";
import { InputBlock } from "../../components/blocks/InputBlock/InputBlock";
import { Logo } from "../../components/common/Logo/Logo";
import { Submit } from "../../components/ui/Submit/Submit";
import { data } from "../../assets/data/data";
import { useFormWithValidation } from "../../hooks/useFormWithValidation";
import { useState } from "react";
import { AuthButtons } from "../../components/blocks/AuthButtons/AuthButtons";

import "./Register.scss";

export const Register = ({
  onRegister,
  response,
  isErrorResponse = true,
  isLoading,
}) => {
  const [confirmPasswordValue, setConfirmPasswordValue] = useState("");
  const [confirmPasswordState, setConfirmPasswordState] = useState(false);
  const [сonfirmPasswordError, setConfirmPasswordError] = useState(false);
  const nameId = "name-signup";
  const emailId = "email-signup";
  const passwordId = "password-signup";
  const passworConfirmdId = "password-confirm-signup";
  const { title, submit, link } = data.register;

  const { isValid, values, handleChange, handleSubmit, errors } =
    useFormWithValidation({
      name: "",
      email: "",
      password: "",
    });
  const submitForm = handleSubmit(() => {
    onRegister(values);
  });

  const handleConfirmPassInputChange = ({ target }) => {
    setConfirmPasswordValue(target.value);
    if (target.value === values.password) {
      setConfirmPasswordState(true);
      setConfirmPasswordError("");
    } else {
      setConfirmPasswordState(false);
      setConfirmPasswordError("Пароли не совпадают");
    }
  };

  return (
    <section className="register">
      <div className="register__inner-container">
        <Link className="register__logo-link" to="/">
          <Logo baseClass="register" />
        </Link>
        <Title baseClass="register">{title}</Title>
        <Form
          baseClass="register"
          onSubmit={submitForm}
          name="signIn"
          isLoading={isLoading}
        >
          <fieldset className="form__inner-container">
            <InputBlock
              labelText="Имя"
              baseClass="register"
              labelPos="top"
              isCustomValidated={true}
              errorText={errors.name}
              isBordered={true}
              id={nameId}
              type="text"
              name="name"
              value={values.name}
              onChange={handleChange}
              minLength={2}
              maxLength={30}
              required
              disabled={isLoading}
              pattern="[A-Za-zА-Яа-яЁё\-\s]{1,}"
            />
            <InputBlock
              labelText="E-mail"
              baseClass="register"
              labelPos="top"
              isCustomValidated={true}
              errorText={errors.email}
              isBordered={true}
              id={emailId}
              type="email"
              name="email"
              value={values.email}
              disabled={isLoading}
              onChange={handleChange}
              required
            />
            <InputBlock
              labelText="Пароль"
              labelPos="top"
              baseClass="register"
              isCustomValidated={true}
              errorText={errors.password}
              isBordered={true}
              id={passwordId}
              type="password"
              name="password"
              value={values.password}
              onChange={handleChange}
              disabled={isLoading}
              required
            />
            <InputBlock
              labelText="Подтверждение пароля"
              labelPos="top"
              baseClass="register"
              isCustomValidated={true}
              errorText={сonfirmPasswordError}
              isBordered={true}
              id={passworConfirmdId}
              type="password"
              name="confirm-password"
              value={confirmPasswordValue}
              onChange={handleConfirmPassInputChange}
              disabled={isLoading}
            />
          </fieldset>
          <div className="form__handles-container">
            <span
              className={`form__response ${
                response ? "form__response_visible" : ""
              } ${isErrorResponse ? "form__response_is-error" : ""}`}
            >
              {response}
            </span>
            <Submit
              isValid={isValid && confirmPasswordState && !isLoading}
              submitText="Зарегистрироваться"
            />
          </div>
        </Form>
        <p className="text register__text">
          {link.description}&nbsp;
          <Link className="link register__link" to={link.href}>
            {link.text}
          </Link>
        </p>
        <AuthButtons isLoading={isLoading} />
      </div>
    </section>
  );
};
