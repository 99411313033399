import Popup from "../../common/Popup/Popup";
import { FillGoalForm } from "../../forms/FillGoalForm/FillGoalForm";
import "./FillGoalPopup.scss";

export const FillGoalPopup = ({ isOpen, onClose, ...props }) => {
  return (
    <Popup isOpen={isOpen} onClose={onClose} title="Пополнить цель">
      <FillGoalForm isOpen={isOpen} onClose={onClose} {...props} />
    </Popup>
  );
};
