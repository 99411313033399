import { useState } from "react";
import "./ButtonRound.scss";

export const ButtonRound = ({
  onClick,
  icon,
  type,
  baseClass,
  size,
  withAnim = false,
  ariaLabel = "Нажать",
  externalActive = false,
  children,
  ...props
}) => {
  const [isActive, setisActive] = useState(externalActive);

  const handleClick = () => {
    onClick();
    setisActive(!isActive);
  };

  const generalClass = "button-round";

  const classList = [
    `${generalClass}`,
    `${type ? `${generalClass}_type_${type}` : ""}`,
    `${icon ? `${generalClass}_icon_${icon}` : ""}`,
    `${size ? `${generalClass}_size_${size}` : ""}`,
    `${baseClass ? baseClass : ""}`,
    `${isActive && withAnim && externalActive ? `${generalClass}_active` : ""}`,
  ].join(" ");

  return (
    <button
      type="button"
      onClick={handleClick}
      aria-label={ariaLabel}
      className={classList}
      {...props}
    >
      {children}
    </button>
  );
};
