import "./ProgressBar.scss";

export const ProgressBar = ({ isCompleted, progress }) => {
  return (
    <div className="progress-bar">
      <span
        className="progress-bar__text"
        style={{
          left: `${isCompleted ? "100%" : `${progress}%`}`,
          transform: `translate(${
            progress >= 96 || isCompleted
              ? "-100%"
              : progress <= 4
              ? "0%"
              : "-50%"
          }, -25%)`,
        }}
      >
        {isCompleted ? "100%" : `${parseInt(progress)}%`}
      </span>
      <progress
        className="progress-bar__bar"
        max="100"
        value={isCompleted ? 100 : progress}
      />
    </div>
  );
};
