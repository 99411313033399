import { Bar } from "react-chartjs-2";
import { Subtitle } from "../../blocks/Subtitle/Subtitle";
import {
  Chart as ChartJS,
  BarElement,
  Title as BarTtile,
  CategoryScale,
  LinearScale,
} from "chart.js";
import "./ProfileChart.scss";
import { GROUPS } from "../../../utils/constants";

ChartJS.register(BarElement, BarTtile, LinearScale, CategoryScale);

export const ProfileChart = ({ ratingData }) => {
  const options = {
    // indexAxis: "y",
    responsive: true,
    plugins: {},
    scales: {
      y: {
        ticks: {
          stepSize: 1,
        },
      },
      x: {
        grid: {
          drawOnChartArea: false,
        },
      },
    },
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
  };

  const data = {
    labels: Object.values(GROUPS),
    datasets: [
      {
        data: ratingData,
        backgroundColor: "#80d0c7",
        borderColor: "#424242",
        borderRadius: "5",
      },
    ],
  };

  return (
    <section className="profile-chart">
      <Subtitle baseClass="profile-chart">Статистика</Subtitle>
      <Bar className="profile-chart__chart" options={options} data={data} />
    </section>
  );
};
