const { NODE_ENV, REACT_APP_URL_PROD, REACT_APP_URL_DEV } = process.env;

/**
 * Проверка ответа на запрос к серверу
 * @param { Promise } res - возвращаемый при fetch-запросе объект
 * @returns { Object } - возвращаемый объект переведен в json-формат и содержит готовые данные
 */
const getResponseData = (res) => {
  if (!res.ok) {
    return Promise.reject(res.status);
  }
  return res.json();
};

/**
 * Осуществление запроса к серверу
 * @param { string } url - эндпойнт запроса
 * @param { string } options - объект конфигурации запроса
 * @returns { Promise } - возвращаемый объект переведен в json-формат и содержит готовые данные
 */

export const request = (url = "/", options) => {
  return fetch(
    `${
      NODE_ENV === "production" ? REACT_APP_URL_PROD : REACT_APP_URL_DEV
    }${url}`,
    options
  ).then((res) => getResponseData(res));
};

export function debounce(callee, timeoutMs) {
  return function perform(...args) {
    let previousCall = window.lastCall;
    window.lastCall = Date.now();

    if (previousCall && window.lastCall - previousCall <= timeoutMs) {
      clearTimeout(window.lastCallTimer);
    }

    window.lastCallTimer = setTimeout(() => callee(...args), timeoutMs);
  };
}
