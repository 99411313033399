import { useState } from "react";
import { ConfirmPopup } from "../../../components/blocks/ConfirmPopup/ConfirmPopup";
import Preloader from "../../../components/common/Preloader/Preloader";
import { ShowTeamPopup } from "../../../components/blocks/ShowTeamPopup/ShowTeamPopup";
import { PageHeader } from "../../../components/common/PageHeader/PageHeader";
import { EditGoalPopup } from "../../../components/blocks/EditGoalPopup/EditGoalPopup";
import { FillGoalPopup } from "../../../components/blocks/FillGoalPopup/FillGoalPopup";
import * as goalsApi from "../../../utils/goalApi";
import { GoalsHandlers } from "../../../components/blocks/GoalsHandlers/GoalsHandlers";
import { GoalsList } from "../../blocks/GoalsList/GoalsList";

import "./GoalsListSection.scss";

export const GoalsListSection = ({
  goals,
  isGoalsLoading,
  onSetGoals,
  currentGoal,
  onDeleteCard,
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [isFillPopupOpen, setIsFillPopupOpen] = useState(false);
  const [showedGoals, setShowedGoals] = useState([]);
  const [isShowTeamPopupOpen, setIsShowTeamPopupOpen] = useState(false);
  const [isFinishGoalPopupOpen, setIsFinishGoalPopupOpen] = useState(false);
  const [isActiveGoalPopupOpen, setIsActiveGoalPopupOpen] = useState(false);
  const [showPopupTeam, setShowPopupTeam] = useState([]);
  const [isCompleteConfirmPopupOpen, setIsCompleteConfirmPopupOpen] =
    useState(false);
  const [currentGoalId, setCurrentGoalId] = useState("");
  const [isReturnConfirmPopupOpen, setIsReturnConfirmPopupOpen] =
    useState(false);

  const updateGoal = async (data) => {
    setIsLoading(true);

    try {
      const newGoal = await goalsApi.updateGoal(data, currentGoalId);
      onSetGoals((goals) =>
        goals.map((g) => (g._id === newGoal._id ? newGoal : g))
      );
      setIsLoading(false);
      return Promise.resolve();
    } catch (err) {
      setIsLoading(false);
      console.log(err);
      return Promise.reject(err);
    }
  };

  const handleActiveClick = async () => {
    updateGoal({ isActive: true }).then(() => {
      setIsActiveGoalPopupOpen(false);
    });
  };

  const handleFinishClick = async () => {
    updateGoal({ isActive: false }).then(() => {
      setIsFinishGoalPopupOpen(false);
    });
  };

  const handleReturnClick = async () => {
    updateGoal({ completed: false }).then(() => {
      setIsReturnConfirmPopupOpen(false);
    });
  };

  const handleCompleteGoal = async () => {
    updateGoal({ completed: true }).then(() => {
      setIsCompleteConfirmPopupOpen(false);
    });
  };

  return (
    <>
      <section className="goals">
        {isGoalsLoading && <Preloader />}
        <PageHeader withLogo={true}>Мои цели</PageHeader>
        <GoalsHandlers goals={goals} setShowedGoals={setShowedGoals} />
        <GoalsList
          showedGoals={showedGoals}
          onSetCurrentGoalId={setCurrentGoalId}
          onSetIsShowTeamPopupOpen={setIsShowTeamPopupOpen}
          onSetShowPopupTeam={setShowPopupTeam}
          onSetConfirmCompletePopup={setIsCompleteConfirmPopupOpen}
          onSetIsReturnConfirmPopupOpen={setIsReturnConfirmPopupOpen}
          onSetIsFinishPopupOpen={setIsFinishGoalPopupOpen}
          onSetIsActivePopupOpen={setIsActiveGoalPopupOpen}
          setIsEditPopupOpen={setIsEditPopupOpen}
          setIsFillPopupOpen={setIsFillPopupOpen}
          isFillPopupOpen={isFillPopupOpen}
          {...props}
        />
      </section>
      <EditGoalPopup
        currentGoal={currentGoal}
        onSetGoals={onSetGoals}
        isOpen={isEditPopupOpen}
        onClose={() => setIsEditPopupOpen(false)}
      />
      <FillGoalPopup
        currentGoal={currentGoal}
        onSetGoals={onSetGoals}
        isOpen={isFillPopupOpen}
        onClose={() => setIsFillPopupOpen(false)}
      />
      <ShowTeamPopup
        isShowTeamPopupOpen={isShowTeamPopupOpen}
        onSetIsShowTeamPopupOpen={setIsShowTeamPopupOpen}
        showPopupTeam={showPopupTeam}
      />
      <ConfirmPopup
        isOpen={isCompleteConfirmPopupOpen}
        title="Достигли цели?"
        setIsOpen={setIsCompleteConfirmPopupOpen}
        confirmFunc={handleCompleteGoal}
        isLoading={isLoading}
      />
      <ConfirmPopup
        isOpen={isReturnConfirmPopupOpen}
        title="Вернуть обратно?"
        setIsOpen={setIsReturnConfirmPopupOpen}
        confirmFunc={handleReturnClick}
        isLoading={isLoading}
      />
      <ConfirmPopup
        isOpen={isFinishGoalPopupOpen}
        title="Завершить цель для всей команды?"
        setIsOpen={setIsFinishGoalPopupOpen}
        confirmFunc={handleFinishClick}
        isLoading={isLoading}
      />
      <ConfirmPopup
        isOpen={isActiveGoalPopupOpen}
        title="Вернуть цель в активные для всей команды?"
        setIsOpen={setIsActiveGoalPopupOpen}
        confirmFunc={handleActiveClick}
        isLoading={isLoading}
      />
    </>
  );
};
