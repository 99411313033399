import { Logo } from "../../components/common/Logo/Logo";
import { ButtonRounded } from "../../components/ui/ButtonRounded/ButtonRounded";
import "./Main.scss";

export const Main = ({ isLogged }) => {
  const date = new Date();
  const currentYear = date.getFullYear();

  return (
    <section className="main">
      <section className="main__header">
        <Logo baseClass="main" />
        <div className="main__handles">
          {isLogged ? (
            <ButtonRounded
              href="/goal"
              baseClass="main__button"
              type="green"
              icon="user"
            >
              Аккаунт
            </ButtonRounded>
          ) : (
            <>
              <ButtonRounded
                href="/signin"
                baseClass="main__button"
                type="green"
              >
                Войти
              </ButtonRounded>
              <ButtonRounded
                href="/signup"
                baseClass="main__button"
                type="gray"
              >
                Регистрация
              </ButtonRounded>
            </>
          )}
        </div>
      </section>
      <section className="promo">
        <h1 className="promo__title">
          <span>Goals</span>Team
        </h1>
        <p className="promo__description">Твои цели. Твоя команда.</p>
      </section>
      <section className="team-description">
        <h2 className="team-description__title">
          <span>
            <b>Формируй</b> команду.
          </span>
          <span>Создавай общие цели.</span>
          <span>Сделай процесс достижения целей еще более увлекательным.</span>
        </h2>
      </section>
      <section className="goal-description">
        <h2 className="goal-description__title">
          <span>
            <b>Создавай</b> свои цели.
          </span>
          <span>Дели цели на этапы.</span>
          <span>
            Ставь цели в разных категориях и следи за своей статистикой
          </span>
        </h2>
      </section>
      <footer className="footer">
        <span className="footer__copyright">GoalsTeam {currentYear}</span>
      </footer>

      {/* <div className="goals__header">
        <Title baseClass="goals">Мои цели</Title>
        <Logo baseClass="goals" />
      </div> */}
    </section>
  );
};
