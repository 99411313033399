import CirclePreloader from "../../common/CirclePreloader/CirclePreloader";
import { ButtonRounded } from "../../ui/ButtonRounded/ButtonRounded";

import "./ConfirmPopup.scss";

export const ConfirmPopup = ({
  setIsOpen,
  isOpen,
  title,
  confirmFunc,
  isLoading,
}) => {
  const handleBackClick = () => {
    setIsOpen(false);
  };

  return (
    <div className={`confirm-popup ${isOpen ? "confirm-popup_opened" : ""}`}>
      <div className="confirm-popup__container">
        <h3 className="confirm-popup__title">{title}</h3>
        <div className="confirm-popup__content">
          <ButtonRounded
            onClick={handleBackClick}
            type="gray"
            baseClass="onfirm-popup__back"
            disabled={isLoading}
          >
            Отмена
          </ButtonRounded>
          <ButtonRounded
            onClick={confirmFunc}
            type="green"
            baseClass="confirm-popup__confirm"
            disabled={isLoading}
          >
            Подвердить
          </ButtonRounded>
        </div>
        {isLoading && <CirclePreloader />}
      </div>
    </div>
  );
};
