import { useFormWithValidation } from "../../../hooks/useFormWithValidation";
import { Form } from "../../common/Form/Form";
import { InputBlock } from "../../../components/blocks/InputBlock/InputBlock";
import { Submit } from "../../ui/Submit/Submit";
import { useContext, useEffect, useRef, useState } from "react";
import { SelectBlock } from "../../../components/blocks/SelectBlock/SelectBlock";
import userImg from "../../../assets/images/icons/user.png";
import image from "../../../assets/images/icons/image.png";
import { CurrentUserContext } from "../../../contexts/CurrentUserContext";
import { RadioTag } from "../../ui/RadioTag/RadioTag";
import imageCompression from "browser-image-compression";
import { formatDate } from "../../../helpers/formatDate";

import "./CreateGoalForm.scss";
import { timeToMs } from "../../../helpers/timeToMs";

export const CreateGoalForm = ({
  isFormBlocked,
  onCreateGoal,
  userTeams = [],
  isLoading,
}) => {
  const [isTeamExisted, setIsTeamExisted] = useState(true);
  const [exampleImageSrc, setExampleImageSrc] = useState(null);
  const [fileValue, setFileValue] = useState("");
  const fileInput = useRef(null);
  const user = useContext(CurrentUserContext);

  const {
    isValid: isGoalValid,
    values: goalValues,
    handleChange: handleGoalChange,
    handleSubmit: handleGoalSubmit,
    errors: goalErrors,
    handleSetValues: handleSetGoalValues,
  } = useFormWithValidation({
    name: "",
    date: "",
    group: "sport",
    maxCount: 1,
    teamId: "",
    unit: "ед.",
    image: "",
    maxPerOne: "",
    intervalName: "minute",
    intervalCount: "",
    type: "user",
  });

  const handleTeamSelectChange = (e, type = "user", teamId = "") => {
    handleSetGoalValues(e, { type, teamId });
  };

  const onGoalFileChange = (e) => {
    handleGoalChange(e);
    setExampleImageSrc(URL.createObjectURL(e.target.files[0]));
  };

  useEffect(() => {
    const isExistAdminTeams = userTeams.some((team) => team.isAdmin);
    setIsTeamExisted(isExistAdminTeams);
  }, [userTeams]);

  const submitGoalForm = handleGoalSubmit(async () => {
    const {
      name,
      group,
      maxCount,
      date,
      unit,
      maxPerOne,
      teamId,
      image,
      intervalName,
      type,
      intervalCount,
    } = goalValues;

    const options = {
      maxSizeMB: 2,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
      initialQuality: 0.5,
    };

    try {
      let compressedFile = "";

      if (image) compressedFile = await imageCompression(image, options);

      onCreateGoal({
        name,
        group,
        maxCount,
        date,
        unit,
        maxPerOne,
        teamId,
        isCommon: type === "common",
        isUser: type === "user",
        interval: timeToMs(intervalName) * intervalCount,
        image: compressedFile,
      });
    } catch (error) {
      console.log(error);
    }
  });

  const onDeleteFileClick = (e) => {
    setExampleImageSrc(null);
    handleSetGoalValues({ target: fileInput.current }, { image: "" });
    setFileValue("");
  };

  return (
    <Form
      isLoading={isLoading}
      baseClass="goal"
      onSubmit={submitGoalForm}
      name="createGoal"
    >
      <fieldset className="form__inner-container">
        <InputBlock
          labelText="Название"
          baseClass="goal"
          labelPos="top"
          isCustomValidated={true}
          errorText={goalErrors.name}
          isBordered={true}
          id="goal"
          type="text"
          name="name"
          value={goalValues.name}
          onChange={handleGoalChange}
          minLength={2}
          maxLength={60}
          required
          disabled={isLoading}
        />
        <SelectBlock
          labelText="Группа"
          baseClass="goal"
          labelPos="top"
          id="goal-group"
          type="text"
          name="group"
          isBordered={true}
          value={goalValues.group}
          onChange={handleGoalChange}
          required
          disabled={isLoading}
        >
          <option value="sport">Спорт</option>
          <option value="nature">Природа</option>
          <option value="beauty">Красота</option>
          <option value="study">Учеба</option>
          <option value="another">Другое</option>
        </SelectBlock>
        <div className="goal__unit-block">
          <InputBlock
            labelText="Количество"
            baseClass="goal"
            labelPos="top"
            isCustomValidated={true}
            errorText={goalErrors.maxCount}
            isBordered={true}
            id="goal"
            type="number"
            min={1}
            name="maxCount"
            value={goalValues.maxCount}
            onChange={handleGoalChange}
            required
            disabled={isLoading}
          />
          <SelectBlock
            labelText="Ед. изм"
            baseClass="goal"
            labelPos="top"
            id="goal-group"
            type="text"
            name="unit"
            isBordered={true}
            value={goalValues.unit}
            onChange={handleGoalChange}
            required
            disabled={isLoading}
          >
            <option value="ед.">ед.</option>
            <option value="день">день</option>
            <option value="этап">этап</option>
            <option value="₽">₽</option>
            <option value="$">$</option>
            <option value="%">%</option>
          </SelectBlock>
        </div>
        <InputBlock
          labelText="Дата"
          baseClass="goal"
          labelPos="top"
          id="goal-date"
          type="date"
          name="date"
          isBordered={true}
          value={goalValues.date}
          onChange={handleGoalChange}
          required
          disabled={isLoading}
          min={formatDate(new Date())}
        />
        {goalValues.maxCount > 1 && (
          <div className="goal__limit-container">
            <span className="goal__limit-title">
              Ограничение для выполнения цели (если необходимо)
              <br /> (пример - не более 10 единиц за 6 часов)
            </span>
            <div className="goal__limit-block">
              <InputBlock
                labelText="Кол-во единиц"
                baseClass="goal"
                labelPos="top"
                isCustomValidated={false}
                errorText={goalErrors.maxPerOne}
                isBordered={true}
                id="goal"
                type="number"
                name="maxPerOne"
                value={goalValues.maxPerOne}
                onChange={handleGoalChange}
                disabled={isLoading}
              />
              <InputBlock
                labelText="Интервал"
                baseClass="goal"
                labelPos="top"
                isCustomValidated={false}
                errorText={goalErrors.intervalCount}
                isBordered={true}
                id="goal"
                type="number"
                name="intervalCount"
                value={goalValues.intervalCount}
                onChange={handleGoalChange}
                disabled={isLoading}
              />
              <SelectBlock
                labelText="Время"
                baseClass="goal"
                labelPos="top"
                id="goal-group"
                type="text"
                name="intervalName"
                isBordered={true}
                value={goalValues.intervalName}
                onChange={handleGoalChange}
                disabled={isLoading}
              >
                <option value="minute">минута</option>
                <option value="day">день</option>
                <option value="hour">час</option>
                <option value="month">месяц</option>
              </SelectBlock>
            </div>
          </div>
        )}
        <div className="goal__file-wrapper">
          <label className="goal__file-container">
            <div className="goal__file-title">
              <img className="goal__file-icon" src={image} alt="" />
              <p className="goal__file-text">
                И какую-нибудь мотивирующую картинку! Но не более 2Мб.
              </p>
            </div>
            {goalValues.image && (
              <img className="goal__file-image" src={exampleImageSrc} />
            )}
            <input
              id="goal-file"
              type="file"
              ref={fileInput}
              name="image"
              onChange={onGoalFileChange}
              accept=".jpg, .jpeg, .png"
              value={fileValue}
              filename={goalValues.image}
              className="goal__input-file visually-hidden"
              disabled={isLoading}
            />
          </label>
          {goalValues.image && (
            <button
              className="goal__file-close"
              aria-label="Удалить картинку"
              type="button"
              onClick={onDeleteFileClick}
            />
          )}
          <span className={`input__error goal-file-error`}>
            {goalErrors.image}
          </span>
        </div>
        {(isTeamExisted || user.isSuperAdmin) && (
          <div className="goal__radio-tags">
            <RadioTag
              name="teamName"
              onChange={(e) => handleTeamSelectChange(e, "user")}
              value="user"
              disabled={isLoading}
            >
              <img src={userImg} alt="" aria-hidden={true} />
              Мне
            </RadioTag>
            {user.isSuperAdmin && (
              <RadioTag
                name="teamName"
                onChange={(e) => handleTeamSelectChange(e, "common")}
                value="common"
                disabled={isLoading}
              >
                Всем
              </RadioTag>
            )}
            {userTeams.map(
              (team, index) =>
                team.isAdmin && (
                  <RadioTag
                    key={index}
                    name="teamName"
                    onChange={(e) =>
                      handleTeamSelectChange(e, "team", team._id)
                    }
                    value={team.name}
                    disabled={isLoading}
                  >
                    {team.name}
                  </RadioTag>
                )
            )}
          </div>
        )}
      </fieldset>
      <div className="form__handles-container goal__handles-container">
        <Submit isValid={isGoalValid && !isLoading} submitText="Создать цель" />
      </div>
    </Form>
  );
};
