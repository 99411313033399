import { useEffect, useState } from "react";
import { getDeclineNoun } from "../../../helpers/getDeclineNoun";
import "./DayTag.scss";

export const DayTag = ({ date }) => {
  const [residue, setResidue] = useState();
  const [isOverdue, setIsOverdue] = useState(false);

  useEffect(() => {
    const goalDate = new Date(date);
    const today = new Date();
    const msPerDay = 24 * 60 * 60 * 1000;
    const residue =
      Math.round((goalDate.getTime() - today.getTime()) / msPerDay) + 1;
    const result = setResidueDay(residue);

    setIsOverdue(residue <= 0);
    setResidue(result);
  }, [date]);

  const setResidueDay = (residue) => {
    const modResidue = residue <= 0 ? Math.abs(residue) + 1 : Math.abs(residue);
    const lonalyDay = modResidue % 10 === 1 && modResidue % 100 !== 11;
    const nameDay = getDeclineNoun(modResidue, ["день", "дня", "дней"]);

    return residue > 0
      ? `${lonalyDay ? "Остался" : "Осталось"} ${modResidue} ${nameDay}`
      : `Просрочено на ${-residue + 1} ${nameDay}`;
  };

  return (
    <span className={`day-tag ${isOverdue ? "day-tag_is-overdue" : ""}`}>
      {residue}
    </span>
  );
};
