import { useFormWithValidation } from "../../../hooks/useFormWithValidation";
import { Form } from "../../common/Form/Form";
import { InputBlock } from "../../blocks/InputBlock/InputBlock";
import { Submit } from "../../ui/Submit/Submit";
import * as counterApi from "../../../utils/counterApi";
import { useState } from "react";
import { MESSAGES } from "../../../utils/constants";

export const CreateCounterForm = ({
  onSetResponse,
  onSetIsInfoPopupOpen,
  onSetUserCounters,
  closeSpoiler,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { isValid, values, handleChange, handleSubmit, errors, resetForm } =
    useFormWithValidation({
      name: "",
      unit: "",
    });

  const submitCountForm = handleSubmit(async () => {
    setIsLoading(true);
    try {
      const counter = await counterApi.createCounter(values);
      onSetUserCounters((counters) => [counter, ...counters]);

      onSetResponse(MESSAGES.successCreateCounter);
      onSetIsInfoPopupOpen(true);
      setIsLoading(false);

      closeSpoiler();
      resetForm({ name: "", unit: "" });

      setTimeout(() => {
        onSetIsInfoPopupOpen(false);
      }, 2000);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  });

  return (
    <Form
      baseClass="goal__create-count-form"
      onSubmit={submitCountForm}
      name="createCount"
      isLoading={isLoading}
    >
      <fieldset className="form__inner-container">
        <InputBlock
          labelText="Название"
          baseClass="goal"
          labelPos="top"
          isCustomValidated={true}
          errorText={errors.name}
          isBordered={true}
          id="count-name"
          type="text"
          name="name"
          value={values.name}
          onChange={handleChange}
          minLength={2}
          maxLength={60}
          required
          disabled={isLoading}
        />
        <InputBlock
          labelText="Единицы измерения"
          baseClass="goal"
          labelPos="top"
          isCustomValidated={true}
          errorText={errors.unit}
          isBordered={true}
          id="count-unit"
          type="text"
          name="unit"
          value={values.unit}
          onChange={handleChange}
          minLength={2}
          maxLength={60}
          required
          disabled={isLoading}
        />
      </fieldset>
      <Submit isValid={!isLoading && isValid} submitText="Создать счетчик" />
    </Form>
  );
};
