import PropTypes from "prop-types";
import { useState } from "react";
import { Input } from "../../common/Input/Input";
import "./SelectBlock.scss";

/**
 * Компонент блока инпута формы с лейблом и кастомизированной ошибкой
 * @component
 * @param { Object } props
 * @param { string } props.id - id инпута, к которму будет привязан label
 * @param { boolean } props.isValidated - состояние кастомной валидации - добавляется спан для ошибки
 * @param { string } props.labelText - текст лейбла
 * @param { string } props.labelPos - [top, center] - позиция лейбла по отношению к инпуту
 * @param { string } props.baseClass - - базовый класс раздела, к которму будет относиться заголовок (создание класса с
 * индивидуальными характеристиками)
 * @param { Array } props.props - параметры, передаваемые в инпут (требования к заполнению, имя инпута и др)
 */

export const SelectBlock = ({
  id,
  labelText,
  labelPos = "top",
  baseClass,
  errorText,
  isCustomValidated,
  isBordered,
  children,
  ...props
}) => {
  return (
    <div className="select__container">
      <label
        className={`input__label input__label_pos_${labelPos}`}
        htmlFor={id}
      >
        {labelText}
      </label>
      <select
        id={id}
        className={`select ${baseClass}__select ${
          isBordered ? "select_is-bordered" : ""
        }`}
        {...props}
      >
        {children}
      </select>
    </div>
  );
};
