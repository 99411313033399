import { useFormWithValidation } from "../../../hooks/useFormWithValidation";
import { MESSAGES } from "../../../utils/constants";
import { InputBlock } from "../../blocks/InputBlock/InputBlock";
import { Submit } from "../../ui/Submit/Submit";
import * as teamsApi from "../../../utils/teamApi";
import { useState } from "react";
import CirclePreloader from "../../common/CirclePreloader/CirclePreloader";
import { Form } from "../../common/Form/Form";

export const CreateTeamForm = ({
  onSetUserTeams,
  onSetResponse,
  onSetIsInfoPopupOpen,
  closeSpoiler,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { isValid, values, handleChange, handleSubmit, errors, resetForm } =
    useFormWithValidation({
      name: "",
    });

  const submitTeamForm = handleSubmit(async () => {
    setIsLoading(true);
    try {
      const team = await teamsApi.createTeam(values);
      onSetUserTeams((teams) => [team, ...teams]);

      setIsLoading(false);
      onSetResponse(MESSAGES.successCreateTeam);
      onSetIsInfoPopupOpen(true);
      resetForm({ name: "" });
      closeSpoiler();

      setTimeout(() => {
        onSetIsInfoPopupOpen(false);
      }, 2000);
    } catch (err) {
      setIsLoading(false);
      if (err === 409) {
        onSetResponse(MESSAGES.teamExistError);
        onSetIsInfoPopupOpen(true);

        setTimeout(() => {
          onSetIsInfoPopupOpen(false);
        }, 2000);
      } else {
        console.log(err);
      }
    }
  });

  return (
    <Form
      baseClass="create-team-form"
      onSubmit={submitTeamForm}
      name="createTeam"
      isLoading={isLoading}
    >
      <fieldset className="form__inner-container">
        <InputBlock
          labelText="Название"
          baseClass="goal"
          labelPos="top"
          isCustomValidated={true}
          errorText={errors.name}
          isBordered={true}
          id="goal"
          type="text"
          name="name"
          value={values.name}
          onChange={handleChange}
          minLength={2}
          maxLength={60}
          required
          disabled={isLoading}
        />
      </fieldset>
      <Submit isValid={!isLoading && isValid} submitText="Создать команду" />
    </Form>
  );
};
