import { Subtitle } from "../../blocks/Subtitle/Subtitle";
import { TeamCard } from "../../blocks/TeamCard/TeamCard";
import "./ProfileListBlock.scss";

export const ProfileListBlock = ({
  isLoading,
  items = [],
  row: Row,
  title,
  emptyText,
  ...props
}) => {
  return (
    <section className="profile-list-block">
      <Subtitle baseClass="profile-list-block">{title}</Subtitle>
      {isLoading ? (
        <p className="profile-list-block__text">Загружаем...</p>
      ) : items.length > 0 ? (
        <ul className="profile-list-block__list">
          {items.map((team, index) => (
            <Row key={index} item={team} {...props} />
          ))}
        </ul>
      ) : (
        <p className="profile-list-block__text">{emptyText}</p>
      )}
    </section>
  );
};
