export const timeToMs = (time) => {
  let a = 60 * 1000;

  switch (true) {
    case time === "minute":
      break;
    case time === "hour":
      a = a * 60;
      break;
    case time === "day":
      a = a * 60 * 24;
      break;
    case time === "month":
      a = a * 60 * 24 * 30;
      break;
  }

  return a;
};
