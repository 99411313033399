import "./Popup.scss";

const Popup = ({ isOpen = true, onClose, baseClass = "", title, children }) => {
  return (
    <div className={`popup ${isOpen ? "popup_opened" : ""} ${baseClass}`}>
      <div className="popup__container">
        <h2 className="popup__title">{title}</h2>
        <button
          className="popup__close-btn btn"
          aria-label="Закрыть окно"
          type="button"
          onClick={onClose}
        />
        {children}
      </div>
    </div>
  );
};

export default Popup;
