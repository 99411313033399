import { Form } from "../../components/common/Form/Form";
import { InputBlock } from "../../components/blocks/InputBlock/InputBlock";
import { Submit } from "../../components/ui/Submit/Submit";
import { Title } from "../../components/common/Title/Title";
import { data as userData } from "../../assets/data/data";
import { useFormWithValidation } from "../../hooks/useFormWithValidation";
import { useContext, useMemo } from "react";
import { CurrentUserContext } from "../../contexts/CurrentUserContext";
import { useEffect } from "react";
import { useState } from "react";
import * as teamsApi from "../../utils/teamApi";
import * as auth from "../../utils/mainApi";
import * as counterApi from "../../utils/counterApi";
import { ConfirmPopup } from "../../components/blocks/ConfirmPopup/ConfirmPopup";
import { EditCounterPopup } from "../../components/blocks/EditCounterPopup/EditCounterPopup";
import { ProfileListBlock } from "../../components/sections/ProfileListBlock/ProfileListBlock";
import { TeamRow } from "../../components/blocks/TeamRow/TeamRow";
import { CounterRow } from "../../components/blocks/CounterRow/CounterRow";
import { ProfileChart } from "../../components/sections/ProfileChart/ProfileChart";
import { GROUPS } from "../../utils/constants";
import { ButtonRounded } from "../../components/ui/ButtonRounded/ButtonRounded";

import "./Profile.scss";
import Preloader from "../../components/common/Preloader/Preloader";
import { TeamsSection } from "../../components/sections/TeamsSection/TeamsSection";

export const Profile = ({
  onEdit,
  response = "",
  isErrorResponse = true,
  isFormModify,
  onSetFormModifyStatus,
  onSignout,
  onSetUserTeams,
  onSetUserGoals,
  userCounters,
  userTeams,
  isCountersLoading,
  onSetUserCounters,
  goals,
  isProfileLoading,
  isTeamsLoading,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [ratingData, setRatingData] = useState(null);
  const user = useContext(CurrentUserContext);
  const nameId = "name-profile";
  const emailId = "email-profile";
  const { edit, signout, greet } = userData.profile;
  const [isConfirmExitPopupOpen, setIsConfirmExitPopupOpen] = useState(false);
  const [isConfirmDeletePopupOpen, setIsConfirmDeletePopupOpen] =
    useState(false);
  // const [isConfirmDeleteCounterPopupOpen, setIsConfirmDeleteCounterPopupOpen] =
  //   useState(false);
  const [isEditCounterPopupOpen, setIsEditCounterPopupOpen] = useState(false);
  const [isCounterLoading, setIsCounterLoading] = useState(false);
  const [currentTeam, setCurrentTeam] = useState({ id: "" });
  const completedGoals = useMemo(
    () =>
      goals.filter((goal) => {
        const myGoal = goal.teamUsers.find(
          (teamUser) => teamUser._id === user._id
        );

        if (!myGoal) return;

        return myGoal.completed;
      }),
    [goals]
  );

  useEffect(() => {
    const goalsGroup = completedGoals.map((goal) => goal.group);
    const ratingGoals = {};
    goalsGroup.forEach((group) => {
      if (ratingGoals[GROUPS[group]]) {
        ratingGoals[GROUPS[group]] += 1;
      } else {
        ratingGoals[GROUPS[group]] = 1;
      }
      setRatingData(ratingGoals);
    });
  }, [completedGoals]);

  const [currentCounter, setCurrentCounter] = useState({
    name: "",
    unit: "",
    count: 0,
    id: "",
  });
  const { isValid, values, handleChange, handleSubmit, setValues } =
    useFormWithValidation({ name: "" });

  const userDataValidiity = isValid && user.name !== values.name;

  useEffect(() => {
    setValues({ name: user.name });
  }, [setValues, user]);

  const submitForm = handleSubmit(() => {
    onEdit(values);
  });

  const handleEditClick = () => onSetFormModifyStatus(true);

  const handleSignoutClick = () => onSignout();

  const handleExitClick = async (id) => {
    setCurrentTeam({ id });
    setIsConfirmExitPopupOpen(true);
  };

  const handleDeleteClick = async (id) => {
    setCurrentTeam({ id });
    setIsConfirmDeletePopupOpen(true);
  };

  const handleDeleteCounterClick = async (counter) => {
    const { name, unit, count, _id } = counter;

    setCurrentCounter({ name, unit, count, id: _id });
    setIsEditCounterPopupOpen(true);
  };

  const handleConfirmExitClick = async () => {
    setIsLoading(true);

    try {
      await teamsApi.leaveTeam(currentTeam.id);
      onSetUserGoals((goals) =>
        goals.filter((goal) => goal.teamId !== currentTeam.id)
      );
      onSetUserTeams((teams) =>
        teams.filter((team) => team._id !== currentTeam.id)
      );
      setIsConfirmExitPopupOpen(false);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdateCounter = async (counterData) => {
    setIsCounterLoading(true);

    try {
      const updatedCounter = await counterApi.updateCounter(
        counterData,
        currentCounter.id
      );

      onSetUserCounters((counters) =>
        counters.map((counter) =>
          counter._id === updatedCounter._id ? updatedCounter : counter
        )
      );
    } catch (err) {
      console.log(err);
    } finally {
      setIsCounterLoading(false);
      setIsEditCounterPopupOpen(false);
    }
  };

  const handleConfirmDeleteCounterClick = async () => {
    setIsCounterLoading(true);
    try {
      const deletedCounter = await counterApi.deleteCounter(currentCounter.id);

      onSetUserCounters((counters) =>
        counters.filter((counter) => counter._id !== currentCounter.id)
      );
    } catch (err) {
      console.log(err);
    } finally {
      setIsCounterLoading(false);
      setIsEditCounterPopupOpen(false);
    }
  };

  const handleConfirmDeleteClick = async () => {
    setIsLoading(true);
    try {
      await teamsApi.deleteTeam(currentTeam.id);

      onSetUserTeams((teams) =>
        teams.filter((team) => team._id !== currentTeam.id)
      );
      setIsConfirmDeletePopupOpen(false);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <section className="profile">
      {(isProfileLoading || isTeamsLoading) && <Preloader />}
      <div className="profile__about">
        {/* <Link className="profile__logo-link" to="/">
          <Logo baseClass="profile" />
        </Link> */}
        <div className="profile__about-inner">
          <div className="profile__about-avatar-container">
            <img className="profile__about-avatar" src={user.avatar} alt="" />
          </div>
          <Title baseClass="profile">{user.name}</Title>
          <p className="profile__goals-count">{`Выполненные цели: ${completedGoals.length}`}</p>
        </div>
      </div>
      <TeamsSection
        row={TeamRow}
        items={userTeams}
        onExitTeam={handleExitClick}
        onDeleteTeam={handleDeleteClick}
        title="Команды"
        emptyText="Вы пока не состоите в командах"
      />
      <ProfileListBlock
        row={CounterRow}
        isLoading={isCountersLoading}
        items={userCounters}
        onDeleteCounter={handleDeleteCounterClick}
        title="Счетчики"
        emptyText="У вас пока нет счетчиков"
      />
      <ProfileChart ratingData={ratingData} />
      <div className="profile__inner-container">
        <Form baseClass="profile__form" onSubmit={submitForm} name="profile">
          <fieldset className="form__inner-container">
            <InputBlock
              labelText="Имя"
              labelPos="top"
              baseClass="profile"
              isBordered={true}
              isCustomValidated={true}
              id={nameId}
              type="text"
              name="name"
              value={values.name}
              onChange={handleChange}
              required
              disabled={!isFormModify}
            />
          </fieldset>
          <div className="form__handles-container">
            {!isErrorResponse ? (
              <span
                className={`form__response ${
                  response ? "form__response_visible" : ""
                }`}
              >
                {response}
              </span>
            ) : (
              ""
            )}

            {isFormModify ? (
              <>
                {isErrorResponse ? (
                  <span
                    className={`form__response form__response_is-error ${
                      response ? "form__response_visible" : ""
                    }`}
                  >
                    {response}
                  </span>
                ) : (
                  ""
                )}
                <Submit
                  isValid={userDataValidiity}
                  submitText={edit.save.text}
                />
              </>
            ) : (
              <div className="profile__buttons">
                <ButtonRounded
                  baseClass="profile__signout"
                  type="gray"
                  onClick={handleSignoutClick}
                >
                  {signout.text}
                </ButtonRounded>
                <ButtonRounded
                  baseClass="profile__edit"
                  type="green"
                  onClick={handleEditClick}
                >
                  {edit.init.text}
                </ButtonRounded>
              </div>
            )}
          </div>
        </Form>
      </div>
      <ConfirmPopup
        isOpen={isConfirmExitPopupOpen}
        title="Подтвержаете выход из команды?"
        setIsOpen={setIsConfirmExitPopupOpen}
        confirmFunc={handleConfirmExitClick}
        isLoading={isLoading}
      />
      <ConfirmPopup
        isOpen={isConfirmDeletePopupOpen}
        title="Подтвержаете удаление команды?"
        setIsOpen={setIsConfirmDeletePopupOpen}
        confirmFunc={handleConfirmDeleteClick}
        isLoading={isLoading}
      />
      <EditCounterPopup
        onDeleteCard={handleConfirmDeleteCounterClick}
        onUpdateCard={handleUpdateCounter}
        currentItem={currentCounter}
        isOpen={isEditCounterPopupOpen}
        onClose={() => setIsEditCounterPopupOpen(false)}
        isLoading={isCounterLoading}
      />
    </section>
  );
};
