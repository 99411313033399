import { request } from "./utils";

/**
 * @returns { Promise.<{Object[]}> } - возвращаемый объект содержит массив счетчиков
 */
export const getCounters = () => {
  return request("/counters", {
    credentials: "include",
  });
};

/**
 * @param { Object } counter - объект с данными о фильме
 * @returns { Promise.<{Object[]}> } - возвращаемый объект содержит объект с данными о счетчике
 */
export const createCounter = (counter) => {
  return request("/counters", {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...counter,
    }),
  });
};

/**
 * @param { string } id - id счетчика
 */
export const deleteCounter = (id) => {
  return request(`/counters/${id}`, {
    method: "DELETE",
    credentials: "include",
  });
};

export const updateCounter = (counterData, id) => {
  return request(`/counters/${id}`, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...counterData,
    }),
  });
};
