import CirclePreloader from "../CirclePreloader/CirclePreloader";
import "./Form.scss";

export const Form = ({ children, baseClass, isLoading, ...props }) => {
  return (
    <form className={`form ${baseClass}`} action="#" {...props}>
      {children}
      {isLoading && <CirclePreloader />}
    </form>
  );
};
