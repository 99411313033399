import { ProgressBar } from "../ProgressBar/ProgressBar";
import "./ShowTeamPopup.scss";

export const ShowTeamPopup = ({
  isShowTeamPopupOpen,
  onSetIsShowTeamPopupOpen,
  showPopupTeam,
}) => {
  const handleClick = () => {
    onSetIsShowTeamPopupOpen(false);
  };

  return (
    <div
      className={`show-team-popup ${
        isShowTeamPopupOpen ? "show-team-popup_opened" : ""
      }`}
    >
      <div className="popup__container">
        <h3 className="show-team-popup__title">Результаты команды</h3>
        <button
          className="popup__close-btn btn"
          aria-label="Закрыть окно"
          type="button"
          onClick={handleClick}
        />
        {showPopupTeam && (
          <ul className="show-team-popup__list">
            {showPopupTeam.map((team, index) => (
              <li className="show-team-popup__item" key={index}>
                <span className="show-team-popup__name">{team.name}</span>
                <ProgressBar
                  isCompleted={team.completed}
                  progress={team.progress * 100}
                />
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
