import "./Tag.scss";

export const Tag = ({ component: Component = "div", icon, name }) => {
  return (
    <Component className="tag">
      {icon && <img src={icon} aria-hidden={true} />}
      <span>{name}</span>
    </Component>
  );
};
