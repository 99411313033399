import { GROUPS_EN } from "../../../../utils/constants";
import "./CardLabel.scss";

export const CardLabel = ({ group }) => {
  return (
    <span
      className={`card-label ${
        GROUPS_EN.includes(group) ? `card-label_type_${group}` : ""
      }`}
    />
  );
};
