import { Spoiler } from "../../common/Spoiler/Spoiler";
import { JoinTeamForm } from "../../forms/JoinTeamForm/JoinTeamForm";

export const JoinTeam = (props) => {
  return (
    <Spoiler btnAriaLabel="Открыть форму" name="Присоединиться к команде">
      <JoinTeamForm {...props} />
    </Spoiler>
  );
};
