import { ButtonRounded } from "../../ui/ButtonRounded/ButtonRounded";

const { NODE_ENV, REACT_APP_URL_PROD, REACT_APP_URL_DEV } = process.env;
const serverURL =
  NODE_ENV === "production" ? REACT_APP_URL_PROD : REACT_APP_URL_DEV;

export const AuthButtons = ({ isLoading }) => {
  const onGoogleClick = async () => {
    try {
      localStorage.setItem("isLogged", true);
      window.open(`${serverURL}/auth/google`, "_self");
    } catch (err) {
      console.log(err);
    }
  };

  // const onVKClick = async () => {
  //   try {
  //     window.open("http://localhost:80/auth/vkontakte", "_self");
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  return (
    <div className="login__auth-container">
      <ButtonRounded
        type="gray"
        icon="google"
        baseClass="login__auth-button"
        onClick={onGoogleClick}
        disabled={isLoading}
      >
        Вход с аккаунтом Google
      </ButtonRounded>
    </div>
  );
};
