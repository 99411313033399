import { ProgressBar } from "../../ProgressBar/ProgressBar";
import "./CardProgress.scss";

export const CardProgress = ({ count, maxCount, unit, completed }) => {
  return (
    <div className="card-progress">
      <span className="card-progress__text">Прогресс</span>
      <span className="card-progress__text">{`${count} / ${maxCount} ${unit}`}</span>
      <ProgressBar
        isCompleted={completed}
        progress={(count * 100) / maxCount}
      />
    </div>
  );
};
