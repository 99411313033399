import React, { useState } from "react";
import {
  Routes,
  Route,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
import { SWRConfig } from "swr";
import { Login } from "../../pages/Login/Login";
import { Main } from "../../pages/Main/Main";
import { PageNotFound } from "../../pages/PageNotFound/PageNotFound";
import { Profile } from "../../pages/Profile/Profile";
import { Register } from "../../pages/Register/Register";
import { Footer, Header } from "../sections/Footer/Header";
import { CurrentUserContext } from "../../contexts/CurrentUserContext";
import * as auth from "../../utils/mainApi";
import * as goalsApi from "../../utils/goalApi";
import * as teamsApi from "../../utils/teamApi";
import * as counterApi from "../../utils/counterApi";

import { MESSAGES } from "../../utils/constants";
import { useEffect } from "react";
import { ProtectedRoute } from "../blocks/ProtectedRoute/ProtectedRoute";
import { Goal } from "../../pages/Goal/Goal";
import { Rating } from "../../pages/Rating/Rating";
import { InfoPopup } from "../blocks/InfoPopup/InfoPopup";
import { Goals } from "../../pages/Goals/Goals";
import { Recommendations } from "../../pages/Recommendations/Recommendations";

import "../../vendor/normalize.css";
import "../../vendor/fonts/fonts.css";
import "../../variables/variables.css";
import "../../common/_typography.scss";
import "./App.scss";

const App = () => {
  const [rating, setRating] = useState({ sport: 0, beauty: 0, stydy: 0 });
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [userTeams, setUserTeams] = useState([]);
  const [userCounters, setUserCounters] = useState([]);
  const [currentUser, setCurrentUser] = useState({
    name: "",
    email: "",
    score: {},
  });
  const [currentGoal, setCurrentGoal] = useState({
    name: "",
    date: "",
    id: "",
    count: 0,
    maxCount: 0,
    lastUpdated: "",
    interval: "",
    maxPerOne: "",
    isTimeTiFill: true,
  });
  const [response, setResponse] = useState("");
  const [isErrorResponse, setIsErrorResponse] = useState(true);
  const [isFormModify, setIsFormModify] = useState(false);
  const [isFormBlocked, setIsFormBlocked] = useState(false);
  const [isLogged, setIsLogged] = useState(
    JSON.parse(localStorage.getItem("isLogged"))
  );
  const [isInfoPopupOpen, setIsInfoPopupOpen] = useState(false);
  const [goals, setGoals] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isTeamsLoading, setIsTeamsLoading] = useState(false);
  const [isCountersLoading, setIsCountersLoading] = useState(false);
  const [isProfileLoading, setIsProfileLoading] = useState(false);
  const [isFillGoalPopupOpen, setIsFillGoalPopupOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const listOfFooterDisplayed = [
    "/profile",
    "/goal",
    "/rating",
    "/goals",
    "/recommendations",
  ];
  const isFooterDisplayed = listOfFooterDisplayed.includes(location.pathname);

  useEffect(() => {
    const isUserLogged = localStorage.getItem("isLogged");

    const fetchInfo = async () => {
      setIsTeamsLoading(true);
      try {
        const teams = await teamsApi.getTeams();

        setUserTeams(teams);

        setIsTeamsLoading(false);
      } catch (err) {
        setIsTeamsLoading(false);
        console.log(err);
      }
    };
    if (isUserLogged) {
      fetchInfo();
    }
  }, [isLogged, goals]);

  useEffect(() => {
    const isUserLogged = localStorage.getItem("isLogged");

    const fetchInfo = async () => {
      setIsProfileLoading(true);
      try {
        const [counters, user, goals] = await Promise.all([
          counterApi.getCounters(),
          auth.getUserInfo(),
          goalsApi.getGoals(),
        ]);

        setGoals(goals);

        setIsLogged(true);
        localStorage.setItem("isLogged", true);
        setCurrentUser(user);

        setUserCounters(counters);

        setIsProfileLoading(false);
      } catch (err) {
        setIsProfileLoading(false);
        if (err === 401) {
          navigate("/", { replace: true });
          setIsLogged(false);
        }

        localStorage.clear();
        console.log(err);
      }
    };
    if (isUserLogged) {
      fetchInfo();
    }
  }, [isLogged]);

  useEffect(() => {
    setResponse("");
    setIsFormModify(false);
    setIsFormBlocked(false);
    setIsInfoPopupOpen(false);
  }, [location]);

  const handleSignUp = async (userData) => {
    setIsLoading(true);

    try {
      const user = await auth.register(userData);
      await auth.authorize({
        email: userData.email,
        password: userData.password,
      });
      localStorage.setItem("isLogged", true);

      setCurrentUser(user);
      setIsLogged(true);
      setIsLoading(false);

      navigate("/profile", { replace: true });
    } catch (err) {
      setIsLoading(false);
    }
  };

  const handleSignIn = async (userData) => {
    setIsLoading(true);

    try {
      setIsFormBlocked(true);

      const { _jwt } = await auth.authorize(userData);
      localStorage.setItem("isLogged", true);
      setIsLogged(true);

      const user = await auth.getUserInfo(_jwt);
      setCurrentUser(user);
      setIsLoading(false);

      navigate("/profile", { replace: true });
    } catch (err) {
      setIsLoading(false);
      setIsFormBlocked(false);
      if (err === 401) {
        setResponse(MESSAGES.incorrectSignin);
      } else {
        setResponse(MESSAGES.serverError);
      }
    }
  };

  const handleSignOut = async () => {
    try {
      await auth.signout();
      localStorage.clear();
      setIsLogged(false);

      navigate("/", { replace: true });
    } catch (err) {
      console.log(err);
    }
  };

  const handleEditProfile = async (userData) => {
    try {
      const user = await auth.editUserInfo(userData);
      setCurrentUser(user);
      setIsFormModify(false);
      setResponse(MESSAGES.successEditProfileMessage);
      setIsErrorResponse(false);
    } catch (err) {
      setIsErrorResponse(true);
      if (err === 409) {
        setResponse(MESSAGES.emailExistError);
      } else {
        setResponse(MESSAGES.profileError);
      }
    }
  };

  const handleCreateGoal = async (goalData) => {
    setIsLoading(true);
    try {
      const newGoal = await goalsApi.createGoal(goalData);
      setGoals((goals) => [...goals, newGoal]);
      setIsFormModify(false);
      setResponse(MESSAGES.successCreateGoal);
      setIsInfoPopupOpen(true);
      setIsErrorResponse(false);
      setIsLoading(false);

      setTimeout(() => {
        setIsInfoPopupOpen(false);
      }, 2000);
    } catch (err) {
      setIsErrorResponse(true);
      setIsLoading(false);
      console.log(err);
    }
  };

  return (
    <SWRConfig
      value={{
        fetcher: (resource, init) =>
          fetch(resource, init).then((res) => res.json()),
      }}
    >
      <CurrentUserContext.Provider value={currentUser}>
        <div className={`root ${isMenuOpened ? "root_blocked" : ""}`}>
          {isFooterDisplayed && (
            <Header
              isLogged={isLogged}
              isMenuOpened={isMenuOpened}
              onChangeMenuOpenness={setIsMenuOpened}
            />
          )}
          <main className="content">
            <Routes>
              <Route path="/" element={<Main isLogged={isLogged} />} />
              <Route
                path="/goals"
                element={
                  isLogged ? (
                    <Goals
                      onSetGoals={setGoals}
                      userTeams={userTeams}
                      currentGoal={currentGoal}
                      onEditGoal={setCurrentGoal}
                      goals={goals}
                      isGoalsLoading={isProfileLoading}
                      isFillGoalPopupOpen={isFillGoalPopupOpen}
                    />
                  ) : (
                    <Navigate to="/" replace />
                  )
                }
              />
              <Route
                path="/profile"
                element={
                  isLogged ? (
                    <Profile
                      isFormModify={isFormModify}
                      onSignout={handleSignOut}
                      onSetFormModifyStatus={setIsFormModify}
                      onEdit={handleEditProfile}
                      userTeams={userTeams}
                      onSetUserTeams={setUserTeams}
                      onSetUserGoals={setGoals}
                      userCounters={userCounters}
                      isCountersLoading={isCountersLoading}
                      onSetUserCounters={setUserCounters}
                      goals={goals}
                      isProfileLoading={isProfileLoading}
                      isTeamsLoading={isTeamsLoading}
                    />
                  ) : (
                    <Navigate to="/" replace />
                  )
                }
              />
              <Route
                path="/signin"
                element={
                  <Login
                    onLogin={handleSignIn}
                    isErrorResponse={isErrorResponse}
                    response={response}
                    isLoading={isLoading}
                  />
                }
              />
              <Route
                path="/signup"
                element={
                  <Register
                    onRegister={handleSignUp}
                    isErrorResponse={isErrorResponse}
                    response={response}
                    isLoading={isLoading}
                  />
                }
              />
              <Route
                path="/goal"
                element={
                  isLogged ? (
                    <Goal
                      onCreateGoal={handleCreateGoal}
                      isFormBlocked={isFormBlocked}
                      isErrorResponse={isErrorResponse}
                      response={response}
                      userTeams={userTeams}
                      onSetUserTeams={setUserTeams}
                      onSetUserGoals={setGoals}
                      onSetUserCounters={setUserCounters}
                      onSetResponse={setResponse}
                      onSetIsInfoPopupOpen={setIsInfoPopupOpen}
                      isLoading={isLoading}
                      isProfileLoading={isProfileLoading}
                    />
                  ) : (
                    <Navigate to="/" />
                  )
                }
              />
              <Route
                path="/rating"
                element={
                  isLogged ? <Rating rating={rating} /> : <Navigate to="/" />
                }
              />
              <Route
                path="/recommendations"
                element={isLogged ? <Recommendations /> : <Navigate to="/" />}
              />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </main>
          <InfoPopup
            isInfoPopupOpen={isInfoPopupOpen}
            onSetIsInfoPopupOpen={setIsInfoPopupOpen}
            response={response}
          />
        </div>
      </CurrentUserContext.Provider>
    </SWRConfig>
  );
};

export default App;
