import { Logo } from "../../common/Logo/Logo";
import { useLocation, Link, NavLink } from "react-router-dom";
import "./Header.scss";
import user from "../../../assets/images/icons/user.png";
import team from "../../../assets/images/icons/team.png";
import star from "../../../assets/images/icons/star.png";
import target from "../../../assets/images/icons/target.png";

export const Header = ({ isLogged = true, ...props }) => {
  const location = useLocation();
  const isMain = location.pathname === "/";

  const data = [
    // {
    //   name: "Рекомендации",
    //   href: "/recommendations",
    //   icon: { src: target, alt: "" },
    // },
    { name: "Мои цели", href: "/goals", icon: { src: team, alt: "" } },
    { name: "Создать", href: "/goal", icon: { src: target, alt: "" } },
    { name: "Профиль", href: "/profile", icon: { src: user, alt: "" } },
  ];
  return (
    <header className="header">
      <nav className="header__nav">
        <ul className="header__nav-list">
          {data.map((item, index) => (
            <li className="header__nav-item" key={index}>
              <NavLink
                className={({ isActive }) =>
                  `link header__nav-link ${
                    isActive ? "header__nav-link_is-active" : ""
                  }`
                }
                to={item.href}
              >
                <img
                  className="header__nav-icon"
                  src={item.icon.src}
                  alt={item.icon.alt}
                />
                <span className="header__nav-name">{item.name}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
};
