import "./CirclePreloader.scss";

const CirclePreloader = () => {
  return (
    <div className="circle">
      <div className="circle__progress" />
    </div>
  );
};

export default CirclePreloader;
