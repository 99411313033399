import { useEffect } from "react";
import { useState } from "react";
import { useFormWithValidation } from "../../../hooks/useFormWithValidation";
import { ButtonRounded } from "../../ui/ButtonRounded/ButtonRounded";
import { InputBlock } from "../InputBlock/InputBlock";
import PopupWithForm from "../PopupWithForm/PopupWithForm";

import "./EditCounterPopup.scss";

export const EditCounterPopup = ({
  isOpen,
  onClose,
  onUpdateCard,
  isLoading = false,
  currentItem,
  onDeleteCard,
}) => {
  const [fillState, setFillState] = useState(true);

  useEffect(() => {
    if (isOpen) {
      setValues({ count: 0 });
    }
  }, [isOpen]);

  const { values, handleChange, handleSubmit, errors, setValues } =
    useFormWithValidation({
      count: 0,
    });

  const submitForm = handleSubmit(() => {
    const { count } = currentItem;

    const res = fillState ? count + +values.count : count - values.count;

    onUpdateCard({ count: res });
  });

  return (
    <PopupWithForm
      onDeleteCard={onDeleteCard}
      cardId={currentItem.id}
      onSubmit={submitForm}
      isOpen={isOpen}
      onClose={onClose}
      title="Пополнить счетчик"
      name="edit-counter"
      isLoading={isLoading}
    >
      <InputBlock
        labelText="Количество"
        baseClass="counter"
        labelPos="top"
        isCustomValidated={true}
        errorText={errors.name}
        isBordered={true}
        id="counter-count"
        type="number"
        name="count"
        value={values.count}
        onChange={handleChange}
        min={0}
        disabled={isLoading}
      />

      <div className="edit-counter__tabs">
        <ButtonRounded
          type="light-bordered"
          isActive={fillState}
          onClick={() => setFillState(true)}
          disabled={isLoading}
        >
          Добавить
        </ButtonRounded>
        <ButtonRounded
          type="light-bordered"
          isActive={!fillState}
          onClick={() => setFillState(false)}
          disabled={isLoading}
        >
          Убрать
        </ButtonRounded>
      </div>
    </PopupWithForm>
  );
};
