import { useState, useEffect } from "react";
import { formatDate } from "../../../helpers/formatDate";
import { useFormWithValidation } from "../../../hooks/useFormWithValidation";
import * as goalsApi from "../../../utils/goalApi";
import { Form } from "../../common/Form/Form";
import { ButtonRounded } from "../../ui/ButtonRounded/ButtonRounded";
import { InputBlock } from "../../blocks/InputBlock/InputBlock";

import "./EditGoalForm.scss";

export const EditGoalForm = ({ onClose, currentGoal, onSetGoals }) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    isValid,
    values,
    handleChange,
    handleSubmit,
    errors,
    setValues,
    setErrors,
  } = useFormWithValidation({
    name: currentGoal.name,
    date: currentGoal.date,
  });

  const isInvalidForm =
    (values.name === currentGoal.name &&
      values.date === formatDate(currentGoal.date)) ||
    !isValid ||
    isLoading;

  useEffect(() => {
    const { name, date } = currentGoal;

    setValues({
      name,
      date: formatDate(date),
    });
    setErrors({});
  }, [currentGoal.name, currentGoal.date, onClose]);

  const submitForm = handleSubmit(async () => {
    setIsLoading(true);
    try {
      const newGoal = await goalsApi.updateGoal(values, currentGoal.id);
      onSetGoals((goals) =>
        goals.map((g) => (g._id === newGoal._id ? newGoal : g))
      );
      setIsLoading(false);
      onClose();
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  });

  const handleDeleteCard = async () => {
    setIsLoading(true);
    try {
      await goalsApi.deleteGoal(currentGoal.id);
      onSetGoals((goals) =>
        goals.filter((goal) => goal._id !== currentGoal.id)
      );

      setIsLoading(false);
      onClose();
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  return (
    <Form
      onSubmit={submitForm}
      id="edit-goal"
      name="edit"
      isLoading={isLoading}
      baseClass="edit-goal-form"
    >
      <InputBlock
        labelText="Название"
        baseClass="goal"
        labelPos="top"
        isCustomValidated={true}
        errorText={errors.name}
        isBordered={true}
        id="goal"
        type="text"
        name="name"
        value={values.name}
        onChange={handleChange}
        minLength={2}
        maxLength={60}
        disabled={isLoading}
        required
      />
      <InputBlock
        labelText="Дата"
        baseClass="goal"
        labelPos="top"
        id="goal-date"
        type="date"
        name="date"
        isBordered={true}
        value={values.date}
        onChange={handleChange}
        required
        min={formatDate(new Date())}
        disabled={isLoading}
      />
      <ButtonRounded
        onClick={handleDeleteCard}
        type="gray"
        baseClass="edit-goal-form__delete"
        disabled={isLoading}
      >
        Удалить
      </ButtonRounded>
      <ButtonRounded
        isSubmit="true"
        type="blue"
        baseClass="edit-goal-form__save"
        disabled={isInvalidForm}
      >
        Сохранить
      </ButtonRounded>
    </Form>
  );
};
