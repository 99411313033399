import { useFormWithValidation } from "../../../hooks/useFormWithValidation";
import { InputBlock } from "../../blocks/InputBlock/InputBlock";
import { Submit } from "../../ui/Submit/Submit";
import * as teamsApi from "../../../utils/teamApi";
import { MAX_EXAMPLE_COUNT, MESSAGES } from "../../../utils/constants";
import { useMemo, useState } from "react";
import { debounce } from "../../../utils/utils";
import { RadioTag } from "../../ui/RadioTag/RadioTag";
import { Form } from "../../common/Form/Form";

import "./JoinTeamForm.scss";

export const JoinTeamForm = ({
  onSetUserTeams,
  onSetUserGoals,
  onSetResponse,
  onSetIsInfoPopupOpen,
  userTeams = [],
  spoilerRef,
  setSpoilerHeight,
  closeSpoiler,
}) => {
  const [radioChecked, setRadioChecked] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [displayingTeamExamples, setDsplayingTeamExamples] = useState([]);
  const [displayAddButton, setDisplayAddButton] = useState(false);
  const [teamExamples, setTeamExamples] = useState([]);
  const userTeamNames = useMemo(
    () => userTeams.map((team) => team.name),
    [userTeams]
  );
  const {
    isValid,
    values,
    handleChange,
    handleSubmit,
    errors,
    handleSetValues,
    resetForm,
  } = useFormWithValidation({
    name: "",
  });

  const getTeamExamples = async (e) => {
    if (e.target.value.length < 2) return;

    try {
      const teams = await teamsApi.getExampleTeams(e.target.value);

      if (!teams) return;

      const newTeams = teams.filter(
        (team) => !userTeamNames.includes(team.name)
      );

      const displayResult = [];
      if (newTeams.length >= MAX_EXAMPLE_COUNT) {
        displayResult.push(
          ...newTeams.filter((item, index) => index < MAX_EXAMPLE_COUNT)
        );

        setDisplayAddButton(true);
      } else {
        setDisplayAddButton(false);

        displayResult.push(...newTeams);
      }

      setTeamExamples(teams);
      setDsplayingTeamExamples(displayResult);
      setTimeout(() => {
        setSpoilerHeight(spoilerRef.current.scrollHeight);
      }, 20);
    } catch (err) {
      console.log(err);
    }
  };

  const handleNameChange = (e) => {
    setRadioChecked("");
    handleChange(e);
    debounce(getTeamExamples, 600)(e);
  };

  const handleExampleClick = async (e) => {
    handleSetValues(e, { name: e.target.value });
    setRadioChecked(e.target.value);
  };

  const handleAddExamplesClick = (e) => {
    e.preventDefault();

    const displayResult = [];

    const examplesCount = displayingTeamExamples.length + MAX_EXAMPLE_COUNT;

    if (examplesCount < teamExamples.length) {
      displayResult.push(
        ...teamExamples.filter((item, index) => index < examplesCount && item)
      );
      setDisplayAddButton(true);
    } else {
      setDisplayAddButton(false);

      displayResult.push(...teamExamples);
    }

    setDsplayingTeamExamples(displayResult);

    setTimeout(() => {
      setSpoilerHeight(spoilerRef.current.scrollHeight);
    }, 0);
  };

  const submitJoinTeamForm = handleSubmit(async () => {
    setIsLoading(true);
    try {
      const team = await teamsApi.joinToTeam(values);

      onSetUserGoals((goals) => [...team.goals, ...goals]);
      onSetUserTeams((teams) => [team, ...teams]);
      setIsLoading(false);
      onSetResponse(MESSAGES.successJoinTeam);
      onSetIsInfoPopupOpen(true);

      setTimeout(() => {
        onSetIsInfoPopupOpen(false);
      }, 2000);

      resetForm({ name: "" });
      setDsplayingTeamExamples([]);
      closeSpoiler();
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  });

  return (
    <Form
      baseClass="create-team-form"
      onSubmit={submitJoinTeamForm}
      name="joinTeam"
      isLoading={isLoading}
    >
      <InputBlock
        labelText="Название"
        baseClass=""
        labelPos="top"
        isCustomValidated={true}
        errorText={errors.name}
        isBordered={true}
        id="goal"
        type="text"
        name="name"
        autoComplete="off"
        value={values.name}
        onChange={handleNameChange}
        minLength={2}
        maxLength={60}
        required
        disabled={isLoading}
      />
      <ul className="join-team-form__examples">
        {displayingTeamExamples.map((example, index) => (
          <li key={index}>
            <RadioTag
              onChange={(e) => handleExampleClick(e)}
              name="teamName"
              value={example.name}
              required
              disabled={isLoading}
              checked={example.name === radioChecked}
            >
              {example.name}
            </RadioTag>
          </li>
        ))}
        {displayAddButton && (
          <li className="join-team-form__add-btn-container">
            <button
              onClick={handleAddExamplesClick}
              className="join-team-form__add-btn"
            />
          </li>
        )}
      </ul>
      <Submit
        isValid={!isLoading && isValid && radioChecked}
        submitText="Присоединиться"
      />
    </Form>
  );
};
