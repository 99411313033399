import { useRef, useState, cloneElement } from "react";
import { ButtonRound } from "../../ui/ButtonRound/ButtonRound";

import "./Spoiler.scss";

export const Spoiler = ({ children, name, btnAriaLabel }) => {
  const [isSpoilerOpened, setIsSpoilerOpened] = useState(false);
  const [spoilerHeight, setSpoilerHeight] = useState(0);

  const ref = useRef(null);

  const handleOpenClick = () => {
    setIsSpoilerOpened(!isSpoilerOpened);
    setSpoilerHeight(isSpoilerOpened ? 0 : ref.current.scrollHeight);
  };

  const closeSpoiler = () => {
    setSpoilerHeight(0);
    setIsSpoilerOpened(false);
  };

  return (
    <div className="spoiler">
      <p className="spoiler__subtitle">{name}</p>
      <ButtonRound
        externalActive={isSpoilerOpened}
        ariaLabel={btnAriaLabel}
        onClick={handleOpenClick}
        icon="plus"
        type="green"
        size="small"
        withAnim={true}
        baseClass="spoiler__btn"
      />
      <div className="spoiler__outer" style={{ height: `${spoilerHeight}px` }}>
        <div ref={ref} className="spoiler__inner">
          {cloneElement(children, {
            setSpoilerHeight,
            spoilerRef: ref,
            closeSpoiler,
          })}
        </div>
      </div>
    </div>
  );
};
